import { API } from "config/apis";


const getAllCreator = (skip,limit) =>
  API.get(`/admin/getCreator?skip=${skip}&limit=${limit}`)
    .then((res) => res)
    .catch((err) => err);

    const accessCreattor = (id, data) =>
    API.patch(`/admin/api/users/${id}/status`, data)
      .then((res) => res)
      .catch((err) => err);   

export const getALLCreatorService = {
    getAllCreator,
    accessCreattor
};