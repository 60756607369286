import React, { useState} from "react";
import "./scss/index.scss";
import { Box, Text, Heading } from "@chakra-ui/react";
import { Btn } from "component";
import { useDispatch , useSelector} from "react-redux";
import { authActions } from "store/actions";
import { NavLink , useNavigate} from "react-router-dom";
// import {toast} from "react-toastify";


export const OTP = () => {



  const dispatch=useDispatch();
  const naviagte=useNavigate();
  // const selector=useSelector(state => state.authentication)
  // console.log(selector);
  let [otpValue, setOtpValue] = useState({
    value: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    // disable: true,
  });

  const handleChange = (value1, event) => {
    // console.log(value1);
    setOtpValue({ ...otpValue, [value1]: event.target.value });
  };


    

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      // console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        // console.log(elmnt);
        elmnt.target.form.elements[next].focus();
      }
    }
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.target);
    // get the object values convert into an array
    let value=Object.values(otpValue);
    // from first index from an array
    value.shift();
    // convert array into an string and remove all , from string
    const res=value.toString().replace(/,/g, "")
    let finalRes={
      email: localStorage.getItem("email"),
      otpKey:res
    };
    const forgetPassOtp={
      email: localStorage.getItem("emailForgetPass"),
      otpKey: res
    }
    if(finalRes.email == null){
      console.log("Forget Password", forgetPassOtp)
      dispatch(authActions.verifyOtpForPass(forgetPassOtp, naviagte));
      // dispatch(authActions.optNumber(forgetPassOtp, naviagte))   
    }else{
      console.log(finalRes); 
      dispatch(authActions.optNumber(finalRes, naviagte))
    }

  };
  
  return (
    <Box className="opt_Section">

      <Box className="nested_otpSection flex justify-center items-center">
        <Box className="otp_FormConatiner flex flex-col justify-center items-center">
          <Box className="heading_Otp">
            <Heading as={"h1"} paddingY="18px">
              Verify Account
            </Heading>
            {/* <button onClick={notify}>Notify!</button> */}
          </Box>
          <form  className="otp_FormSection">
            <Box
              paddingY={"14px"}
              className="flex justify-center items-center gap-3 input_Div"
            >
              <input
                name="otp1"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otpValue.otp1}
                //   onKeyPress={keyPressed}
                onChange={(e) => handleChange("otp1", e)}
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                name="otp2"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otpValue.otp2}
                onChange={(e) => handleChange("otp2", e)}
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />

              <input
                name="otp3"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otpValue.otp3}
                onChange={(e) => handleChange("otp3", e)}
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                name="otp4"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otpValue.otp4}
                onChange={(e) => handleChange("otp4", e)}
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />

              <input
                name="otp5"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otpValue.otp5}
                onChange={(e) => handleChange("otp5", e)}
                tabIndex="5"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                name="otp6"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otpValue.otp6}
                onChange={(e) => handleChange("otp6", e)}
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
            </Box>
            <Box className="otp_Btn flex justify-center my-4">
              <NavLink>
              <Btn value="Submit" onClick={handleSubmit} />
              </NavLink>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
