import { authConstnts } from "../constants";

let initialState = {
  isLoading: false,
  data: {},
  otpNumber: "",
};

export const authReducer = (state = initialState, action) => {
  console.log("Reducer",action);
  switch (action.type) {
// case login
   case authConstnts.LOGIN_SUCCESSS:
    return{
      ...state,
      isLoading: false,
      data: action.data,
    };  
    case authConstnts.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
      // case signup failure remianing
      case authConstnts.OTP_SUCCESS:
        return{
          ...state,
          isLoading: false,
          data: action.data,
        }
       case authConstnts.CREATOR_CREATED:
        return{
          ...state,
          isLoading: false,
          data: action.data
        }
      // forget password apis
      case authConstnts.EMAIL_SENT:
        return{
          ...state,
          isLoading: false,
          data: action.data
        } 
      case authConstnts.OTP_SENT_Forg_Pass:
        return{
          ...state,
          isLoading: false,
          data: action.data,
        }  
    default:
      return state;
  }
};
