import React, {useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./scss/index.scss";
import { rockets } from 'assets/images';
import {Btn, TextInput} from "component";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useDispatch} from "react-redux";
import { newsletterAction } from "store/actions";
import {RxCross1} from "react-icons/rx"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: '60%',
};


export const NewsLetterModal = ({handleClose,open}) => {
const dispatch=useDispatch()
  const [getEmail, setGetEmail]=useState("")


  const onsubmitFuncEmail=(e) =>{
   e.preventDefault();
   let res={
    email: getEmail
   }
   dispatch(newsletterAction.registernewsLetter(res))
     console.log(res);
  }

  return (
    <div className='newsletter-section'>
         <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open} className='newsLtter_fading'>
            <Box sx={style}>
              {/* <div className='mail_box'>
                <MailOutlineIcon/>
              </div> */}
              <div onClick={handleClose} className='close_btn'>
                <RxCross1/>
              </div>
              <Typography
                id="transition-modal-title"
                variant="h3"
                component="h2"
                className='heading'
              >
               Join our News Letter <span> <img src={rockets} alt="rockets" />
               </span>
              </Typography>
              <Typography id="transition-modal-description" className='desc'>
                This site is in Alpha Phase 1. The Beta version will allow creators to submit application to be a creative partner.  Join the mailing list to stay updated as early adopters and testers will be rewarded when we are in full release!
              </Typography>

              <form className='sub_form' onSubmit={onsubmitFuncEmail}>
                <div>
                  <TextInput type='email' placeholder='Email Address' name='email' onChange={(e) => setGetEmail(e.target.value)} />
                </div>
                <div>
                  <Btn value='Subscribe' disabled={getEmail == "" ? true: false} onClick={handleClose} />
                </div>
              </form>
              <Typography id="transition-modal-description" className='desc'>
                You can unsubscribe at any time!
              </Typography>
            </Box>
          </Fade>
        </Modal>
    </div>
  )
}
