import React,{useState} from 'react';
import "./scss/index.scss";
import { AdminHeader } from 'layout';
import { useParams, useNavigate } from 'react-router-dom';
import {data} from "../../../data"
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {BsFillArrowLeftSquareFill} from "react-icons/bs";
import { useDispatch } from 'react-redux';
import {ugetAllCreatorAction} from "../../../store/actions/adminAction"



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "40%",
  borderRadius: "4px"
};
let chainArra=["Binance Testnet","Goerli","BNB","Polygon Mainnet","Ether"];
const chainLinkMap = {
  "Binance Testnet": "https://testnet.bscscan.com/tx",
  "Goerli": "https://goerli.etherscan.io/tx/",
  "BNB": "https://bscscan.com/tx",
  "Polygon Mainnet": "https://polygonscan.com/tx",
  "Ether": "https://etherscan.io/tx/"
};
export const NestedPageCreators = () => {
    const { id } = useParams();   
    // first Model Open
    const [open, setOpen] = useState(false);
     let [allow, setAllow]=useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
// End First Modal

// Second Modal
const [openSecond, setOpenSecond] = useState(false);
let [meesageValue, setmeesageValue]=useState("");
const handleOpenSecond = () => setOpenSecond(true);
const handleCloseSecond = () => setOpenSecond(false);


    const dispatch=useDispatch();

    const navigation=useNavigate();
    const userData = useSelector((state) => state.getAllCreatorsRed.data); // Access the user object from Redux state


       console.log("All Detals", userData);
      let getFilterData=userData?.filter(value =>{
          return value._id == id
      });
      console.log(getFilterData);

      function shortenTransactionHash(hash, startDigits, endDigits) {
        if (hash?.length <= startDigits + endDigits) {
            return hash;
        } else {
            const startPart = hash?.slice(0, startDigits);
            const endPart = hash?.slice(-endDigits);            
            return  startPart && endPart &&  startPart + "..." + endPart;
        }
    }
    const shortHash = shortenTransactionHash(getFilterData[0]?.transactionHash, 10, 5);
    // console.log("shortHash",shortHash);

    function getChainLink(chainName) {
      let filterData=chainName.filter(value => value == getFilterData[0]?.cryptoMehtod)
      console.log(filterData[0]);
      for (const key in chainLinkMap) {
        console.log("chainLinkMap[key]",key);
        if (key == filterData[0]) {
            return chainLinkMap[key];
        }
    }

    return "No matching key found";
  }
  
  // const chainName = "Binance Testnet";
  const link = getChainLink(chainArra);
  console.log(`Link: ${link}`);


  function StatusComponent(status) {
    let textColorClass = '';

    switch (status) {
        case 'pending':
           return textColorClass = 'pending-color';
            break;
        case 'approved':
          return textColorClass = 'approved-color';
            break;
        case 'rejected':
          return textColorClass = 'rejected-color';
            break;
        default:
            break;
    }
  }
  let colorRes=StatusComponent(getFilterData[0]?.creatorStatus);
  console.log("colorRes",colorRes);


const onSubmitFunc=(e) =>{
   e.preventDefault();
   console.log(allow);
   if(allow == "approved" || allow == "pending"){
    let reqObj={         
      creatorStatus: allow
    }
    dispatch(ugetAllCreatorAction.accessCreator(getFilterData[0]._id,reqObj))
    handleClose();
   }else{
    handleOpenSecond();
    // console.log(meesageValue);
   }
};
const onSubmitFuncTwo=(e)=>{
  e.preventDefault();
  let fullName=getFilterData[0]?.firstName && getFilterData[0]?.lastName && `${getFilterData[0]?.firstName} ${getFilterData[0]?.lastName}`
  let respObj={
    message: meesageValue,
    username: fullName,
    creatorStatus: allow,
    email: getFilterData[0]?.email
  };
  // console.log(getFilterData[0]);
  dispatch(ugetAllCreatorAction.accessCreator(getFilterData[0]._id,respObj))
  handleCloseSecond();
  handleClose();
}
  const navigationFunc=()=>{
    navigation(-1)
  }


  return (
    <div>

    <AdminHeader/>
    <div className='nested_detail_page'>
    <div className='detail_div'>
    <h2 className='text-5xl py-5 flex gap-2'> <span className='cursor-pointer' onClick={navigationFunc}><BsFillArrowLeftSquareFill/></span>  Creator Details</h2>
    <div className='my-6 mx-auto flex justify-between items-center'>
       <h5 className='text-3xl'> <span></span> Creator Status</h5>
       <div>
        {/* <p className={`status-text ${colorRes}`}></p> */}
        <p className={`${colorRes}  p-3 text-center h-14 w-44 rounded-lg text-white text-lg`}>{getFilterData[0]?.creatorStatus.toUpperCase()}</p>
       {/* <button>{}</button> */}
       </div>
    </div>

     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Country's State</TableCell>
            <TableCell>Agreed Rules And Regulations</TableCell>
            <TableCell>Agreed Terms And Conditions</TableCell>
            <TableCell>Language</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Wallet Amount</TableCell>
            <TableCell>Transaction Hash</TableCell>
            <TableCell>Payment Mehtod</TableCell>   
          </TableRow>
        </TableHead>
        <TableBody>
          {getFilterData?.map((value) => (
            <TableRow
              key={value._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {value?.email}
              </TableCell>
              <TableCell>{value?.firstName && value?.lastName && `${value?.firstName} ${value?.lastName}`}</TableCell>
              <TableCell>{value?.country}</TableCell>
              <TableCell>{value?.countryState}</TableCell>
              <TableCell>{value?.agreedRulesAndReg && "Agree"}</TableCell>
              <TableCell>{value?.agreedTermsAndCond && "Agree"}</TableCell>
              <TableCell>{value?.language}</TableCell>
              <TableCell>{value?.phoneNumber}</TableCell>
              <TableCell>{value?.walletAmount}</TableCell>
              <TableCell>
              {shortHash && 
              <a className="underline" target='_blank' href={`${link}/${value.transactionHash}`}>
              {shortHash}  
              </a>
              }
              </TableCell>
              <TableCell>{value?.cryptoMehtod}</TableCell>

              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   <div className='my-5'>
   <button onClick={handleOpen} className='text-xl bg-[#9D0208] p-3 h-14 w-44 rounded-lg text-white'>Change Status</button>
   </div>
    </div>
    </div>

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" className='text-center' variant="h4" component="h2">
              Creator Status
            </Typography>
            <form onSubmit={onSubmitFunc} className={`btn_status my-4`}>
              <button onClick={() => setAllow("approved")}>Approved</button>
              <button onClick={() => setAllow("pending")}>Pending</button>
              <button onClick={() => setAllow("rejected")}>Rejected</button>
            </form>
          </Box>
        </Fade>
      </Modal>


    {/* reject Modal */}
    <Modal
        aria-labelledby="transition-modal-titletwo"
        aria-describedby="transition-modal-description"
        open={openSecond}
        onClose={handleCloseSecond}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSecond}>
          <Box sx={style}>
            <Typography id="transition-modal-titletwo" className='text-center' variant="h4" component="h2">
              Creator Status
            </Typography>
            <form onSubmit={onSubmitFuncTwo} className={`btn_status my-4`}>
                <label htmlFor="message" className='text-xl'>Message</label>
              <textarea name="" id="message" className='text_area' cols="30" rows="10" onChange={(e) => setmeesageValue(e.target.value)}></textarea>
              <button type='submit' className='submit_btn_access' >Submit</button>
            </form>
          </Box>
        </Fade>
      </Modal>



    </div>
  )
}
