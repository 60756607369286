import { API } from "config/apis";

const uploadMovies = (data) =>
  API.post(
    "/uploadMoviesApi/uploadMovies",
    data,
    {
      headers: {
        "token": `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res)
    .catch((err) => err);

const getdMovies = () =>
  API.get("/uploadMoviesApi/getMovies", {
    headers: {
      token: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res)
    .catch((err) => err);

export const uploadMoviesService = {
  uploadMovies,
  getdMovies,
};
