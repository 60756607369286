import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Signup,
  Login,
  ChooseSection,
  InitialForm,
  ForgetPassword,
  OTP,
  ResetPassword,
  CreatorProfile,
  DashBoardCreator,
  AccountCreator,
  LibraryCreator,
  SettingCreator,
  AuthorizatedDasboard,
  UploadSection,
  Creators,
  AdminUsers,
  NestedPage,
  NestedPageCreators,
} from "modules/index";
import { TopSection } from "modules/index";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserSignup } from "modules/authentication/UserAuth";

export const MainRouting = () => {
  //  let {creatorStatus}=user?.data;

  const userData = useSelector((state) => state.authentication.data); // Access the user object from Redux state
  let [creatorAccess, setCreatorStatus] = useState({});

  useEffect(() => {
    if (userData) {
      setCreatorStatus(userData?.data);
      console.log("userData", userData?.data);
    }
    const convertedData = JSON.parse(localStorage.getItem("auth"));
    console.log("convertParse", convertedData);
    setCreatorStatus(convertedData?.data);
  }, [userData]);
  console.log("creatorStatus11", userData);
  console.log("creatorStatus", creatorAccess?.creatorStatus);

  return (
    <div>
      <Routes>
        <Route path="/" element={<TopSection />} />
        {/* Auth */}
        <Route path="/creator-signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/choose-section" element={<ChooseSection />} />
        <Route path="/creator-form" element={<InitialForm />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/otp-code" element={<OTP />} />
        <Route path="creator-profile" element={<CreatorProfile />}>
          {creatorAccess?.creatorStatus == "pending" ||
          creatorAccess?.creatorStatus == "rejected" ? (
            <>
              <Route index element={<DashBoardCreator />} />
              <Route path="dashboard-creator" element={<DashBoardCreator />} />
            </>
          ) : (
            <>
              <Route index element={<UploadSection />} />
              <Route path="dashboard-creator" element={<UploadSection />} />
            </>
          )}

          <Route path="account-creator" element={<AccountCreator />} />
          <Route path="library-creator" element={<LibraryCreator />} />
          <Route path="setting-creator" element={<SettingCreator />} />
        </Route>
        <Route path="/admin-creator" element={<Creators />} />
        <Route path="/admin-user" element={<AdminUsers />} />
        <Route path="/dynamic/:id" element={<NestedPage />} />
        <Route path="/creatordetails/:id" element={<NestedPageCreators />} />

        {/* user Section Start */}
        {/* user Auth */}
        <Route path="/user-signup" element={<UserSignup />} />
      </Routes>
    </div>
  );
};
// const mapStateToProps = (state) => ({
//   user: state.authentication.data,
// });
// export const MainRouting = connect(mapStateToProps)(MainRoutings);
