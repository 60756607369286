import React, { useEffect } from "react";
import "./App.scss";
import { MainRouting } from "layout";
import { NewsLetterModal } from "modules";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(`${localStorage.getItem("signup-otp")}`);
  useEffect(() => {
    setTimeout(() =>{
      handleOpen();
    },5000)
  }, []);

  return (
    <div>
      <ToastContainer />
      <MainRouting />
      <div className="news_Letter_Modal">
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* <NewsLetterModal open={open} handleClose={handleClose} /> */}
      </div>
    </div>
  );
}

export default App;
