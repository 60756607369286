import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { newsletterReducer } from "./newsletterReducer";
import { uploadMoviesReducer, getMoviesReducer } from "./uploadMoviesReducer";
import { getAllCreatorsReducer, giveAccessCreatorReducer } from "./adminReducer";

const rootReducer = combineReducers({
  authentication: authReducer,
  newsletterRegistration: newsletterReducer,
  uploadMoviesRed: uploadMoviesReducer,
  getMoviesRed: getMoviesReducer,
  getAllCreatorsRed: getAllCreatorsReducer,
  giveAccessCreatorReducer: giveAccessCreatorReducer,
});
export default rootReducer;
