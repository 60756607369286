import React, { useState } from "react";
import "./scss/index.scss";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { personaluser, papermeeting, docs } from "assets/images/index";
import { StepOne, StepTwo, StepThree } from "../index";
import { FormNavbar } from "layout/index";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "store/actions";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const InitialForm = () => {
  const dispatch = useDispatch();
  // const selector=useSelector(state => state);
  // console.log(selector);
  let navigate = useNavigate();

  const [addNumber, setAddNumber] = useState(0);
  const [checkedItems, setCheckedItems] = useState(false);
  const [socilamedia, setSocialMedia] = useState([]);
  const [socialportfolio, setSocialPortfolio] = useState([]);
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    // walletAddress: "",
    country: "",
    countryState: "",
    phoneNumber: "",
    language: "",
    // transactionHash: "",
    // walletAmount:"",
    // cryptoMehtod: "",
  });

  const handleChange = (value, event) => {
    setInput({
      ...input,
      [value]: event.target.value,
    });
  };

  const onchangeInput = (val, index) => {
    let temp = socilamedia;
    temp[index] = val.target.value;
    setSocialMedia(temp);
    // console.log(temp);
  };
  const onChangeportfolio = (val, index) => {
    let tempportoflio = socialportfolio;
    tempportoflio[index] = val.target.value;
    setSocialPortfolio(tempportoflio);
  };

  const formSubmit = (e) => {
    // e.preventDefault();
    console.log("input", input);
    let res = {
      ...input,
      // acceptedRules: checkedItems,
      SocialMediaAccounts: [...socilamedia],
      SocialPortfolios: [...socialportfolio],
      // walletAddress: "0x3131314243563453",
      // transactionHash: "6sahgdjh273xnmbsd721sahgas1",
      // walletAmount: "0.621",
      // cryptoMehtod: "bnb",
      agreedRulesAndReg: checkedItems,
    };
    console.log(res);
   

    dispatch(authActions.creatorDetails(res,navigate))
  };

  return (
    <>
      <FormNavbar />
      <Box className="creator_Details">
        <Box className="nested_creatorDetails pt-20">
          {/* Stepper Section Start */}
          <Flex justify={"space-around"} align="center" className="steppers">
            {/* Box 1 */}
            <Flex
              gap={"12px"}
              justify="center"
              align="center"
              className={
                addNumber === 0 ? "stepper_Active" : "stepper_Completed"
              }
            >
              <Image
                src={personaluser}
                alt="personaluser"
                width={"36px"}
                height="36px"
              />
              <Text>Personal Information</Text>
            </Flex>

            {/* Box 2 */}
            <Flex
              gap={"12px"}
              justify="center"
              align="center"
              className={
                addNumber === 1
                  ? "stepper_Active"
                  : addNumber > 1
                  ? "stepper_Completed"
                  : ""
              }
            >
              <Image src={docs} alt="docs" width={"36px"} height="36px" />
              <Text>Portfolio Information</Text>
            </Flex>
            {/* Box 3 */}
            <Flex
              gap={"12px"}
              justify="center"
              align="center"
              className={
                addNumber === 2
                  ? "stepper_Active"
                  : addNumber > 2
                  ? "stepper_Completed"
                  : ""
              }
            >
              <Image
                src={papermeeting}
                alt="papermeeting"
                width={"36px"}
                height="36px"
              />
              <Text>Rules & Information</Text>
            </Flex>
          </Flex>
          {/* Stepper Section End */}
          {/* section Two Dynamic From */}
          {addNumber === 0 ? (
            <StepOne
              addNumber={addNumber}
              setAddNumber={setAddNumber}
              input={input}
              handleChange={handleChange}
            />
          ) : addNumber === 1 ? (
            <StepTwo
              addNumber={addNumber}
              setAddNumber={setAddNumber}
              onchangeInput={onchangeInput}
              onChangeportfolio={onChangeportfolio}
            />
          ) : (
            <StepThree
              handleChangeFunc={handleChange}
              input={input}
              setInput={setInput}
              checkedItems={checkedItems}
              formSubmit={formSubmit}
              setCheckedItems={setCheckedItems}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
