import React from "react";
import PropTypes from "prop-types";
import { Input } from '@chakra-ui/react'

export const PasswordInput = (props) => {
  return (
    <div>
      <Input
        type="password"
        className="input"
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        onBlur={props.onBlur}

        // bordered={false}
      />
    </div>
  );
};
PasswordInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};
