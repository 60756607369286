import axios from "axios";
// configration 
let apiCheck;
if(process.env.NODE_ENV === "development"){
     apiCheck = 'https://api.blokflic.com'
}else{
    apiCheck = 'https://api.blokflic.com'
}

const API=axios.create({
    baseURL: apiCheck,
});


export {
    apiCheck,
    API
};

// apiCheck = 'https://api.blokflic.com'
