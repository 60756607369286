import React, { useEffect, useState } from "react";
import "./scss/index.scss";

import axios from "axios";
import SwitchModel from "./SwitchModel";

function CurrentPrice({setInput, input, checkedItems, formSubmit, hash, setHash}) {
  const [hundredDollarToEtherPrice, setHundredDollarToEtherPrice] =
    useState("");
  const [hundredDollarToMaticPrice, setHundredDollarToMaticPrice] =
    useState("");
  const [hundredDollarToBNBPrice, setHundredDollarToBNBPrice] = useState("");
  const [hundredDollarToGoreli, sethundredDollarToGoreli] = useState("");

  const [allNetworkPricesInDollar, setAllNetworkPricesInDollar] = useState();

  const [hundredDollarPrice, setHundredDollarPrice] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum,matic-network&vs_currencies=usd"
        );
        const response2 = await axios.get(
          "https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT"
        );

        const amount_in_dollar = 1;
        const ether_price = response.data.ethereum.usd;
        const matic_price = response.data["matic-network"].usd;
        const BNB_price = response2.data.price;
        // const Goeli_price = response.data.ge.usd;

        setAllNetworkPricesInDollar({ ether_price, matic_price, BNB_price });

        var amount_in_eth = amount_in_dollar / ether_price;
        var amount_in_Matic = amount_in_dollar / matic_price;
        var amount_in_BNB = amount_in_dollar / BNB_price;
        var amount_in_goreli=amount_in_dollar / ether_price;

        setHundredDollarPrice(amount_in_eth);
        setHundredDollarToEtherPrice(amount_in_eth);
        setHundredDollarToMaticPrice(amount_in_Matic);
        setHundredDollarToBNBPrice(amount_in_BNB);
        sethundredDollarToGoreli(amount_in_goreli)
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const [selectedNetwork, setSelectedNetwork] = useState("Ether");

  const handleNetwork = async (network) => {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    if (network === "Ether") {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(1).toString(16)}`,
          },
        ],
      });
      setHundredDollarPrice(hundredDollarToEtherPrice);
    } else if (network === "Matic") {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(137).toString(16)}`,
            chainName: "Polygon Mainnet",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://polygon-rpc.com/"],
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ],
      });
      setHundredDollarPrice(hundredDollarToMaticPrice);
    } else if (network === "BNB") {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(56).toString(16)}`,
            chainName: "Binance Smart Chain Mainnet",
            nativeCurrency: {
              name: "Binance Chain Native Token",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://binance.llamarpc.com"],
            blockExplorerUrls: ["https://bscscan.com"],
          },
        ],
      });
      setHundredDollarPrice(hundredDollarToBNBPrice);
    } else if (network === "Goerli") {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(5).toString(16)}`,
            chainName: "Etherum Testnet",
            nativeCurrency: {
              name: "Goreli Etherum Testnet",
              symbol: "ETH",
              decimals: 18,
            },
            rpcUrls: ["https://rpc.ankr.com/eth_goerli"],
            // blockExplorerUrls: ["https://bscscan.com"],
          },
        ],
      });
      setHundredDollarPrice(hundredDollarToGoreli);
    } else if (network === "Binance Testnet") {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${Number(97).toString(16)}`,
            chainName: "Binance Testnet",
            nativeCurrency: {
              name: "Binance Testnet",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-testnet.publicnode.com"],
            blockExplorerUrls: ["https://testnet.bscscan.com/"],
          },
        ],
      });
      setHundredDollarPrice(hundredDollarToBNBPrice);
    }
    
  };

  let handleChange = async (event) => {
    try {
      const network = event.target.value;
      console.log(network,"network");
      // handleChangeFunc("cryptoMehtod",network);
      setSelectedNetwork(network);
      handleNetwork(network);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between mb-3 max-w-[480px]">
        <span className="">Amount</span>
        <span className="">$1</span>
      </div>
      <div className="relative w-full">
        <select
          value={selectedNetwork}
          onChange={handleChange}
          className="lg:w-[480px] md:w-[375px] sm:w-[98%]  w-[98%] p-3  text-white opacity-80 bg-gradient-to-b from-[#9D0208] to-[#212227] rounded-md shadow-sm border border-gray-500 shadow-gray-500"
        >
          <option value="Ether" className="text-gray-500">
            <p>Ether</p>&nbsp;
            <p></p>
            <p>{hundredDollarToEtherPrice?.toString().slice(0, 6)}</p>
          </option>
          <option value="Matic" className="text-gray-500">
            <p>Matic</p>&nbsp;
            <p></p>
            <p>{hundredDollarToMaticPrice?.toString().slice(0, 6)}</p>
          </option>
          <option value="BNB" className="text-gray-500">
            <p>Binance</p>&nbsp;
            <p></p>
            <p>{hundredDollarToBNBPrice?.toString().slice(0, 6)}</p>
          </option>
          <option value="Goerli" className="text-gray-500">
            <p>Goreli</p>
            <p></p>
            <p>{hundredDollarToGoreli?.toString().slice(0, 6)}</p>
          </option>
          <option value="Binance Testnet" className="text-gray-500">
            <p>Binance Testnet</p>
            <p></p>
            <p>{hundredDollarToBNBPrice?.toString().slice(0, 6)}</p>
          </option>
        </select>
        <br />
        <br />
        <SwitchModel
          DollarPrice={hundredDollarPrice}
          selectedNetwork={selectedNetwork}
          setInput={setInput}
          input={input}
          handleNetwork={handleNetwork}
          allNetworkPricesInDollar={allNetworkPricesInDollar}
          formSubmit={formSubmit}
          checkedItems={checkedItems}
          setHash={setHash}
          hash={hash}
        />
      </div>
    </div>
  );
}

export default CurrentPrice;
