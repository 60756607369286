import { toast } from "react-toastify";
import { getALLCreatorService } from "service/admin.service";
import { get_ALLCreator_Constant, GIVE_ACCESS_SUCCESS  } from "store/constants"; 


const getAllCreators=(skip, limit) =>{
     console.log("skip, limit",skip, limit);
    return(dispatch)=>{
        getALLCreatorService.getAllCreator(skip,limit).then(
         (res) =>{
           console.log(res);
           try{
            if(res.data?.status == 200){
             dispatch({
               type: get_ALLCreator_Constant.GET_SUCCESS,
               data: res?.data?.documents,
             });
            }
 
           }catch(err){
               if(res.response?.data?.status == 404){
                 console.log(res.response?.data?.message);
                }else if(res.response?.data?.status == 400){
                 console.log(res.response?.data?.message);
                }    
           }
         },(error) => {
           // alert(error);
              console.log(error);
         }
 
       ).catch((err) => {
           // toast.err(err);
           console.log(err);
         });
    }
 
 
 }
 

const accessCreator=(id,data)=>{
  return(dispatch)=>{
        getALLCreatorService.accessCreattor(id,data).then(
         (res) =>{
           console.log("res give Access",res);
           try{
            if(res.data?.status == 200){
             dispatch({
               type: GIVE_ACCESS_SUCCESS.GIVE_SUCCESS,
               data: res?.data?.documents,
             });
            }
 
           }catch(err){
               if(res.response?.data?.status == 404){
                 console.log(res.response?.data?.message);
                }else if(res.response?.data?.status == 400){
                 console.log(res.response?.data?.message);
                }    
           }
         },(error) => {
           // alert(error);
              console.log(error);
         }
 
       ).catch((err) => {
           // toast.err(err);
           console.log(err);
         });
        }
}


 export const ugetAllCreatorAction={
    getAllCreators,
    accessCreator,
 }