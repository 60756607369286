import { authConstnts } from "../constants/index";
import { authService } from "../../service/index";
import { toast } from "react-toastify";


const signup = (data, navigate, toast,  onSubmitProps) => {
  console.log(data);
  // const request=() => ({type: authConstnts.SIGNUP_REQUEST})
  //   const success=() =>({
  //     type: authConstnts.LOGIN_SUCCESSS,
  //   });

  return (dispatch) => {
    // dispatch(request());
    authService.signUp(data).then(
      (res) => {
        console.log(res);

        if (res && res.status === 200) {
          // localStorage.setItem("auth", JSON.stringify(res.data));
          // localStorage.setItem("token", res.data.token);
          // dispatch(success({ role: res.data }));
          localStorage.setItem("email", res.data?.user?.email);
          toast.success(res?.data?.message && res?.data?.message);
          dispatch({
            type: authConstnts.SIGNUP_SUCCESS,
            data: res.data,
          });
          onSubmitProps.setSubmitting(false);
          navigate("/otp-code", { replace: true });

          // alert(
          //   "Your account has been registered",
          //   +"Check your email now to get your confirmation link."
          // );
          // dispatch(
          //   modalAction.success({
          //     title: "Your account has been registered",
          //     subtitle: "Check your email now to get your confirmation link.",
          //   })
          // );
          // setTimeout(() => {
          //   history.push("/otp-code");
          //   window.location.reload();
          // }, 0);
          // } else {
          // dispatch(failure(res?.response?.data?.errors[0]?.msg));
          // alert("Not Going on 200");
          // dispatch(
          //   modalAction.warning({
          //     title: res?.response?.data?.errors[0]?.msg,
          //   })
          // );
        } else if (res && res.response?.data?.status === 404) {
          // if (res && res.response?.data?.status === 404) {
          toast.error(res && res.response?.data?.message);
          onSubmitProps.setSubmitting(false);
          // }
        } else {
          toast.error(res && res.response?.data?.message);
          onSubmitProps.setSubmitting(false);
        }
      },
      (error) => {
        // l(false);
        // dispatch(failure(error.toString()));
        // alert(error.toString());
        // console.log(error);
        toast.error(error.toString());
        onSubmitProps.setSubmitting(false);
        // dispatch(
        //   modalAction.warning({
        //     title: error.toString(),
        //   })
        // );
      }
    );
  };
};
const login = (data, navigate, onSubmitProps) => {

  console.log("login", data);
  return (dispatch) => {
    authService.login(data).then(
      (res) => {
        console.log("Res login Data", res);
        if (res && res.data?.status === 200) {
          localStorage.setItem("auth", JSON.stringify(res.data));
          localStorage.setItem("token", res.data?.token);
                
          dispatch({
            type: authConstnts.LOGIN_SUCCESSS,
            data: res.data,
          });
          toast.success(res?.data?.message && res?.data?.message);
          setTimeout(() =>{
            navigate("/creator-profile/dashboard-creator",{ replace: true });       
          }, 4000);
          onSubmitProps.setSubmitting(false);
        } else {
          if (res && res.response?.data?.status == 400) {
            console.log(res.response.data);
            toast.error(res && res.response?.data?.message);
            onSubmitProps.setSubmitting(false);            
          }else if(res && res.response?.data?.status == 404){
            // if the user does not exist
            toast.error(res && res.response?.data?.message);
            onSubmitProps.setSubmitting(false);
          }

        }
      },
      (error) => {
        alert(error);
      }
    );
  };
};
// otp
const optNumber = (data, navigate) => {
  console.log("Actions", data);

  return (dispatch) => {
    authService.otpCode(data).then(
      (res) => {
        if (res?.data?.status == 200) {
          dispatch({
            type: authConstnts.OTP_SUCCESS,
            data: res.data,
          });

          localStorage.setItem("signup-otp",res?.data?.token)
          toast.success(res?.data?.message && res?.data?.message);
          setTimeout(() => {
            navigate("/creator-form", { replace: true });
          }, 6000);
          localStorage.removeItem("email");
        } else {
          toast.warn("Please write correct OTP Code");
        }
      },
      (error) => {
        alert(error);
      }
    );
  };
};
// creator form
const creatorDetails = (data, navigate) => {
  return (dispatch) => {
    authService
      .creatorDetails(data)
      .then(
        (res) => {
          if (res?.data?.status == 200) {
            console.log(res);
            dispatch({
              type: authConstnts.CREATOR_CREATED,
              data: res.data,
            });
            toast.success(res?.data?.message && "Account has been Filled Completely (Thank You)", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setTimeout(() =>{
              navigate("/login",{ replace: true });       
            }, 4000);
          } else {
            if(res?.response?.status == 400){
              toast.warn(res.response?.data?.message , {
                position: toast.POSITION.BOTTOM_RIGHT,
              });  
            }else{
              toast.warn(res.response?.data?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            }
          }
        },
        (err) => {
          alert(err);
        }
      )
      .catch((err) => {
        toast.error(err);
      });
  };
};

// forget password

const forgetPassword = (data, navigate) => {
  console.log(data);
  return (dispatch) => {
    authService
      .forgetPassword(data)
      .then(
        (res) => {
          if (res?.data?.status == 200) {
            localStorage.setItem("emailForgetPass", data.email);
            dispatch({
              type: authConstnts.EMAIL_SENT,
              data: res.data,
            });
            toast.success(res?.data?.message && res?.data?.message);
            navigate("/otp-code", { replace: true });
          }
          //  else if(){

          //  }
          else {
            // console.log(res.response?.data?.message);
            toast.warn(res.response?.data?.message);
          }
        },
        (err) => {
          alert(err);
        }
      )
      .catch((err) => {
        alert("Catch", err);
      });
  };
};

const verifyOtpForPass = (data, navigate) => {
  console.log(data);
  return (dispatch) => {
    authService
      .sent_Otp_ForgPass(data)
      .then(
        (res) => {
          if (res?.data?.status == 200) {
            dispatch({
              type: authConstnts.OTP_SENT_Forg_Pass,
              data: res.data,
            });
            localStorage.setItem("otpkeyForgetPass", data.otpKey);
            toast.success(res?.data?.message && res?.data?.message);
            setTimeout(() => {
              navigate("/reset-password", { replace: true });
            }, 6000);
            // localStorage.removeItem("emailForgetPass");
          } else if (res.response?.data?.status === 400) {
            toast.warn(res.response?.data?.message);
          } else {
            toast.warn(res.response?.data?.message);
          }
        },
        (err) => {
          alert(err);
        }
      )
      .catch((err) => {
        alert(err);
      });
  };
};

// ResetPasswordFunc
const resetPasswordFunc = (data, navigate) => {
  console.log(data);

  return (dispatch) => {
    authService
      .restPassword(data)
      .then(
        (res) => {
          if (res?.data?.status === 200) {
            dispatch({
              type: authConstnts.REST_PASS_SUCCUSS,
              data: res.data,
            });
            localStorage.removeItem("otpkeyForgetPass");
            localStorage.removeItem("emailForgetPass");
            toast.success(res?.data?.message && res?.data?.message);
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 6000);
          } else if (res.response?.data?.status === 400) {
            dispatch({
              type: authConstnts.RES_PASS_FAILED,
              data: res.data,
            });
            toast.warn(res.response?.data?.message);
          } else if (res.response?.data?.status === 403) {
            dispatch({
              type: authConstnts.RES_PASS_FAILED,
              data: res.data,
            });
            toast.warn(res.response?.data?.message);
          } else {
            dispatch({
              type: authConstnts.RES_PASS_FAILED,
              data: res.data,
            });
            toast.error(res.response?.data?.message);
          }
        },
        (err) => {
          alert(err);
        }
      )
      .catch((err) => {
        alert(err);
      });
  };
};

export const authActions = {
  signup,
  login,
  optNumber,
  creatorDetails,
  forgetPassword,
  verifyOtpForPass,
  resetPasswordFunc,
};
