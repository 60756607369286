export const news_letter_constant={
    REGISTRATION_SUCESS: "REGISTRATION_SUCESS",
    
}
export const upload_Movies_Constant={
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
}
export const get_Movies_Constant={
    GET_SUCCESS: "GET_SUCCESS",
}


