import React from "react";
import "./scss/index.scss";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  Checkbox,
  Text,
  Link,
} from "@chakra-ui/react";
import { contentcreator, user } from "assets/images/index";
import {NavLink} from "react-router-dom";

export const ChooseSection = () => {
  return (
    <Box className="choose_Section">
      <Box className="nested_ChooseSection">
        {/* Container Section */}
        <Box className="container_ChooseSection  flex justify-center items-center flex-col">
          <Box className="main_Heading text-center">
            <Heading as={"h1"}>Choose Your Role</Heading>
            <Text>
              Your role will define why are you signing up at BLOKFLIC for, the
              role can be changed later with some additional info.
            </Text>
          </Box>
          {/* Choose Section */}
          <Flex
            justify={"center"}
            alignItems="center"
            gap={"24px"}
            className="select_Section"
            marginY={"22px"}
          >
            {/* <Box> */}
            <Box
              className="flex justify-center items-center flex-col box_1"
            >
              <NavLink to={"/user-signup"}>
              <Box className="circleImg py-7 flex justify-center">
              <img
                src={user}
                alt="user"
                width={"100px"}
                height="130px"
                
              />
              </Box>
              {/* <div className="circleImg"></div> */}
              <Heading as="h2">
                I am here <br /> to Watch
              </Heading>
              </NavLink>
            </Box>
            {/* </Box> */}
            <Box
              className="flex justify-center items-center flex-col box_2"
            >
              <NavLink to={"/creator-signup"}>
              <img
                src={contentcreator}
                alt="contentcreator"
                width={"230px"}
                height="160px"
              />
              <Heading as="h2">
                I am here <br /> Create Content
              </Heading>
              </NavLink>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
