import React, { useContext, useState } from "react";
import { FormData } from "../index";
import "./scss/index.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { arrowUp, tickArrow } from "assets/images";
// import { Btn } from 'component';
import { arrowblackright } from "assets/images";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import AWS from "aws-sdk";
import { InfinitySpin } from "react-loader-spinner";

// import ffmpeg from 'ffmpeg.js'; // Use the appropriate path to ffmpeg.js


export const UploadMovie = () => {
  let {
    setActiveState,
    setSubmitDetails,
    submitDetails,
    setSelectedVideo,
    setSelectedAudio,
    selectedAudio,
    selectedVideo,
    setSelectedImage,
    selectedImage,
    setIsStatus,
    isStatus,
  } = useContext(FormData);

  // const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoFormat, setVideoFormat] = useState({
    uploadMovieUrl: "",
  });
  const [audioFormat, setAudioFormat] = useState({
    uploadMovieUrl: "",
  });
  const [loader, setLoader] = useState(false);

  // This is start for Image Upload Section

  const handleImageSelect = (event) => {
    const file = event.target.files[0];

    setSelectedImage(file);
    console.log("handle", file);
    // setSubmitDetails({
    //   ...submitDetails,
    //   uploadImageUrl: event.target.files[0],
    // });
  };

  // const handleImageFileInputChange = (e) => {
  //   const file = e.target.files[0];
  //   handleFileImage(file);
  // };

  // const handleFileImage = (file) => {
  //   setSelectedImage(file);
  // };

  const getImagePath = () => {

    console.log("getImagePath",selectedImage);
    if (selectedImage) {
      return URL.createObjectURL(selectedImage);
    }
    return null;
  };

  // clear image
  const clearImage = () => {
    setSelectedImage(null);
  };

  // This is end for Image Upload Section

  // This is fo Video Upload Section
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    console.log("Drop File", e.dataTransfer.files[0]);

    // setSubmitDetails({
    //   ...submitDetails,
    //   uploadMovieUrl: e.dataTransfer.files[0],
    // });

    handleFile(file);
    // setSelectedVideo(file);

  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
    console.log("File Input", file);
    // setSubmitDetails({ ...submitDetails, uploadMovieUrl: e.target.files[0] });
  };

  const handleFile = async(file) => {
 
    // if (file && file.type.startsWith("video/")) {
    //   const videoUrl = URL.createObjectURL(file);
    //   console.log("File", file);
    //   console.log(videoUrl);
    //   // const format = file.name.split('.').pop(); // Get the video format (extension)
    //   setVideoFormat({ uploadMovieUrl: videoUrl });
    //   setSelectedVideo(file);
    // }

  // here we add video and audio

  if (!file) {
    return; // No file selected
  }

  if (file.type.startsWith("video/")) {
    // It's a video file
    const videoUrl = URL.createObjectURL(file);
    console.log("Video File", file);
    console.log("Video URL", videoUrl);
    setVideoFormat({ uploadMovieUrl: videoUrl });
    setSelectedVideo(file);
  } else if (file.type.startsWith("audio/")) {
    // It's an audio file
    const audioUrl = URL.createObjectURL(file);
    console.log("Audio File", file);
    console.log("Audio URL", audioUrl);
    setAudioFormat({ uploadMusicUrl: audioUrl });
    setSelectedAudio(file);
  } else {
    // It's neither a video nor audio file, handle it accordingly
    console.warn("Unsupported file type:", file.type);
  }
  };
  // const getVideoPath = () => {
  //   return selectedVideo;
  // };

  // const handleSaveVideo = () => {
  //   const videoPath = getVideoPath();
  //   console.log("Final Path", videoPath);
  //   console.log(videoFormat);
  // };

  // clear Video
  const handleClear = () => {
    setSelectedVideo(null);
  };

  const uploadToS3 = async (file, folder) => {
    const s3 = new AWS.S3({
      accessKeyId: 'AKIAUCLX6VVQFBQKKVWG',
      secretAccessKey: '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',
    });

    const fileName = `${folder}/${Date.now()}-${file.name}`;

    const params = {
      Bucket: "first-blockflic-bucket",
      Key: fileName,
      Body: file,
      // ACL: 'public-read',
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };

  const handleUpload = async () => {
    try {
      // Upload video and thumbnail to S3
      setLoader(true);

      // const [videoUrl, thumbnailUrl] = await Promise.all([
      //   uploadToS3(selectedVideo, "videos"),
      //   uploadToS3(selectedImage, "thumbnails"),
      // ]);
      // Send URLs and userId to your Node.js server
      // const apiResponse = await axios.post('YOUR_NODEJS_API_URL', {
      //   videoUrl,
      //   thumbnailUrl,
      //   userId,
      // });

      let videoUrl, audioUrl, thumbnailUrl;

if (selectedImage) {
  // Upload the thumbnail if it exists
  thumbnailUrl = await uploadToS3(selectedImage, "thumbnails");
}

if (selectedVideo) {
  // Upload video if a video file is selected
  videoUrl = await uploadToS3(selectedVideo, "videos");
}

if (selectedAudio) {
  // Upload audio if an audio file is selected
  audioUrl = await uploadToS3(selectedAudio, "audio");
}

      console.log(
        "Files uploaded and URLs saved successfully!",
        videoUrl,
        audioUrl,
        thumbnailUrl
      );

      if (thumbnailUrl) {
        console.log("chala Final code");
        setSubmitDetails({
          ...submitDetails,
          uploadImageUrl: thumbnailUrl,
          uploadMovieUrl: videoUrl,
          uploadMusicUrl: audioUrl,
        });
        setIsStatus(true);
        setLoader(false);
      }
      // console.log('API Response:', apiResponse.data);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const onSubmitF = (values) => {
    // e.preventDefault();
    console.log("Values", values);
    console.log("States", selectedVideo, selectedImage);
    console.log("sbmitDetails", submitDetails);
    if (selectedVideo != null || selectedAudio != null) {
      // console.log(videoFormat);
      console.log(selectedImage);
      setActiveState(2);
    }
  };

  return (
    <>
      {loader && (
        <div className="loading">
          <h1>
            <InfinitySpin width="200" color="#fff" />
          </h1>
        </div>
      )}
      <Formik
        initialValues={submitDetails}
        // validationSchema={validationSchema}
        // onSubmit={onSubmitFunc}
      >
        <Form>
          <div className={`upload_movies_btn_section`}>
            <div className={`w-11/12 nested_upload_movies_btn_section`}>
              <div className="heading_upload_movie">
                <h1 className="text-white text-4xl overflow-y-hidden py-6">
                  Upload Media
                </h1>
              </div>
              {/* upload Section */}
              {/* <div className="movie_btn">
          <div className="img_uplad_img">
            <img src={arrowUp} alt="arrowUp" className="w-32 h-32" />
          </div>
          <div className="text_section my-3">
            <h3>Drag and drop video files to upload</h3>
            <p>Your videos will be private until you publish them.</p>
          </div>
          <div className="select_btn my-3">
            <button
              className="flex gap-2 items-center justify-center"
              id="upload_media"
              onClick={onFileFunc}
            >
              <img src={tickArrow} alt="tickArrow" className="w-8 h-8" />
              Select File
            </button>
            <input type="file" id="inputField" className="invisible" />
          </div>
        </div> */}
              <div className="upload_section">
                <div className="img_upload">
                  <h1 className="text-white text-xl overflow-y-hidden py-2">
                    Upload Image
                  </h1>
                  <div
                    style={{ border: "1px dashed white", padding: "10px" }}
                    className="movie_btn relative"
                  >
                    {selectedImage && (
                      <button
                        onClick={clearImage}
                        className="z-50 absolute top-3 right-2 text-3xl text-white"
                      >
                        <RxCross2 />
                      </button>
                    )}

                    {selectedImage ? (
                      <img
                        src={getImagePath()}
                        alt="Selected"
                        style={{ maxWidth: "100%" }}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                      />
                    ) : (
                      <>
                        <div className="img_uplad_img">
                          <img
                            src={arrowUp}
                            alt="arrowUp"
                            className="w-32 h-32"
                          />
                        </div>
                        <div className="text_section">
                          <h3>Select a image file for tumbnail:</h3>
                        </div>
                
                        <div className="select_btn">
                          <input type="file" onChange={handleImageSelect} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="video_upload">
                  <h1 className="text-white text-xl overflow-y-hidden py-2">
                    Upload Video
                  </h1>
                  <div
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    style={{ border: "1px dashed white", padding: "10px" }}
                    className="movie_btn relative"
                  >
                    {selectedVideo && (
                      <button
                        onClick={handleClear}
                        className="z-50 absolute top-3 right-2 text-3xl text-white"
                      >
                        <RxCross2 />
                      </button>
                    )}
                    {selectedVideo ?  (
  // Display video if a video file is selected
  <video controls className="max-w-full">
    <source src={videoFormat.uploadMovieUrl} type="video/mp4" />
  </video>
) : selectedAudio ? (
  // Display audio if an audio file is selected
  <audio controls className="max-w-full">
    <source src={audioFormat.uploadMusicUrl} type="audio/mp3" />
  </audio>
): (
                      // </section>
                      <>
                        <div className="img_uplad_img">
                          <img
                            src={arrowUp}
                            alt="arrowUp"
                            className="w-32 h-32"
                          />
                        </div>
                        <div className="text_section">
                          <h3>Drop video or music here or select a file:</h3>
                        </div>
                        <div className="select_btn">
                          <input
                            type="file"
                            onChange={handleFileInputChange}
                            name="uploadMovieUrl"
                          />
                        </div>
                      </>
                    )}

                    {/* <button onClick={handleSaveVideo}>Save Video</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="next_section_btn mt-4">
              <div className="next_btn_first_step flex gap-4 items-center">
                <button
                  className="flex items-center gap-2 justify-center"
                  onClick={() => setActiveState(0)}
                  type="button"
                >
                  Back
                </button>

                {/* <button
                  className="flex items-center gap-2 justify-center"
                  type="submit"
                >
                  {" "}
                  <img src={arrowblackright} alt="arrowblackright" /> Next
                </button> */}
                {isStatus ? (
                  <button onClick={onSubmitF}>Next</button>
                ) : (
                  <button
                    onClick={handleUpload}
                    disabled={!selectedVideo && !selectedImage}
                  >
                    Upload Files
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};
