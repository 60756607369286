export const slidesData = [
    {
      id: 0,
      text: `The first step to working with BlocFlik is to setup your creator profile and customize your creator page.`,
    },
    {
      id: 1,
      text: `Creators will also need to apply for the BlocFlik creative partner program.  Only approved profiles can upload movies and music to the platform.`,
    },
    {
      id: 2,
      text: `Each application is manually reviewed and will receive a decision within 1 week.  We are looking for motivated and seasoned creators.`,
    },
  ];
  