import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";

import { getContract } from "../utils/index";

let abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "buyGS50",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "gs50TokenAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "accessPoolAdd",
        type: "address",
      },
      {
        internalType: "address",
        name: "communityPoolAdd",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[4]",
        name: "adds",
        type: "address[4]",
      },
      {
        internalType: "uint256[4]",
        name: "shares",
        type: "uint256[4]",
      },
      {
        internalType: "bool",
        name: "sale",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "minEthForApp",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "setApplicationShareData",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "AppShareAddresses",
    outputs: [
      {
        internalType: "address",
        name: "add1",
        type: "address",
      },
      {
        internalType: "address",
        name: "add2",
        type: "address",
      },
      {
        internalType: "address",
        name: "add3",
        type: "address",
      },
      {
        internalType: "address",
        name: "add4",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "AppShares",
    outputs: [
      {
        internalType: "uint256",
        name: "add1Share",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "add2Share",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "add3Share",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "add4Share",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "sale",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "total",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minEth",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "getApplicationShareData",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "add1",
            type: "address",
          },
          {
            internalType: "address",
            name: "add2",
            type: "address",
          },
          {
            internalType: "address",
            name: "add3",
            type: "address",
          },
          {
            internalType: "address",
            name: "add4",
            type: "address",
          },
        ],
        internalType: "struct MasterPaymentContract.AppShareAddsData",
        name: "",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "add1Share",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "add2Share",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "add3Share",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "add4Share",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "sale",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "total",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minEth",
            type: "uint256",
          },
        ],
        internalType: "struct MasterPaymentContract.AppSharesData",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "gs50Token",
    outputs: [
      {
        internalType: "contract IGS50",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const useContract = () => {
  const { library, account, chainId } = useWeb3React();
            
  return useMemo(
    () =>
      !!library
        ? getContract(abi, getAddress(chainId), library, account)
        : undefined,
    [library, account, chainId]
  );
};
// export const useContract = () => {
//   const { library, account, chainId } = useWeb3React();

//   return useMemo(() => {
//     if (!!library) {
//       const contract = getContract(abi, getAddress(chainId), library, account);
//       return contract ? contract.contractProperty : undefined;
//     } else {
//       return undefined;
//     }
//   })
// };

export function getAddress(chainId) {
  if (chainId === 1) {
    return "0x14D8D5055353589024e9fC55d0B06d77f3EbD709";
  } else if (chainId === 5) {
    return "0xBB9d704524E819797BD89e0dd0e8924e734d7EF2";
  } else if(chainId === 137){
     return "0x08379334C2Be9Ac21ee4a61220Eb8711e01C78F6";
  } else if(chainId === 97){
    return "0x2C38F6689C84a864826265159D195421D664c4D8";
 }else if(chainId === 56){
    return "0x7a3F72Ffa468a6491C0B19F65eC230c03aBA77b4";
 }
}
