import React from "react";
import PropTypes from "prop-types";
import "./scss/index.scss";
import { Button} from "@chakra-ui/react";

export const Btn = (props) => {
  // console.log(props.load);
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      className={"btn flex justify-center items-center gap-3"}
      style={{
        background: props.bgColor,
        color: props.color,
        border: props.border,
        padding: props.padding,
      }}
      type="submit"
    >
      {/* <Stack> */}
        {props.img && <img src={props.img} />}
        {props.icon && <props.icon/>}
        {props.value && props.value}
      {/* </Stack> */}
    </Button>
  );
};

Btn.propTypes = {
  value: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.any,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  load: PropTypes.any,
  padding: PropTypes.string,
  disabled: PropTypes.any,
  setRenderBrain: PropTypes.func,
  img: PropTypes.any,
};
