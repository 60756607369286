import React, { useState } from "react";
import "./scss/index.scss";
import { slidesData } from "data/slides";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Flex, Heading, Checkbox, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/actions";
import { userSignIcon, userPlusWhite, logo_white } from "assets/images/index";
import { TextInput, PasswordInput, Btn } from "component/index";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const UserSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checkedItems, setCheckedItems] = useState(false);

  let validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email Format")
      .required("Email Required"),
    password: Yup.string().required("Password Required"),
  });

  let formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // agreedTermsAndCond: checkedItems,
      userType: "User",
    },
    onSubmit: (values, onSubmitProps) => {
      console.log("checkedItems", checkedItems, values);
      let resp = {
        ...values,
        agreedTermsAndCond: checkedItems,
      };
      console.log("resp", resp);
      //  onSubmitProps.setSubmitting(false);
    //   dispatch(authActions.signup(resp, navigate, toast, onSubmitProps));
    },
    validationSchema,
  });
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <>
      <Box className="main_Signup w-full">
        <Box className="nested_Signup">
          {/* container */}
          <Flex
            className="container_Signup"
            height={"100%"}
            width={"90%"}
            marginX={"auto"}
            justify={"space-between"}
            align="flex-end"
          >
            {/* child one About Company */}
            <Box className="content_section mb-4">
              {/* <h1>hell World</h1> */}
              <Box className="logo">
                {/* <Heading as={"h2"}>Logo</Heading> */}
                <img src={logo_white} alt="logo_white" width={"140px"} />
              </Box>
              <Box className="silder-Section">
                <Slider {...settings}>
                  {slidesData.map((value) => {
                    return (
                      <Box key={value.id}>
                        <Text>{value.text}</Text>
                      </Box>
                    );
                  })}
                </Slider>
              </Box>
            </Box>
            {/* form section */}
            <Box className="form_section flex justify-center items-center flex-col">
              <Box>
                <img
                  src={userSignIcon}
                  alt="userSignIcon"
                  width={"165px"}
                  height="120px"
                />
              </Box>
              <Flex gap={"2px"}>
                <Heading as={"h2"} className="heading">
                  Sign Up
                </Heading>
                <span className="flex items-end pb-1">(For Users)</span>
              </Flex>
              <form className="form_inputs" onSubmit={formik.handleSubmit}>
                <Box className="my-7">
                  <label htmlFor="email">Email</label>
                  <TextInput
                    placeholder={"Email"}
                    type="email"
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="pl-2">{formik.errors.email}</div>
                  ) : null}
                </Box>
                <Box className="my-7">
                  <label htmlFor="password">Password</label>
                  <PasswordInput
                    placeholder={"Password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="pl-2">{formik.errors.password}</div>
                  ) : null}
                </Box>

                <Checkbox
                  className="check_Box pl-1"
                  onChange={() => setCheckedItems(!checkedItems)}
                  isChecked={checkedItems}
                  name="agreedTermsAndCond"
                >
                  Terms and Condition
                </Checkbox>
                {formik.touched.agreedTermsAndCond &&
                formik.errors.agreedTermsAndCond ? (
                  <div className="pl-2">{formik.errors.agreedTermsAndCond}</div>
                ) : null}

                <Box
                  marginY={"20px"}
                  className="signup_Btn flex justify-center"
                >
                  <Btn
                    value="Sign up"
                    img={userPlusWhite}
                    disabled={!formik.isValid || formik.isSubmitting}
                  />
                  {/* Sign up 
                </Btn> */}
                </Box>
              </form>
              <Text textAlign={"center"} paddingX="20px" fontSize={"16px"}>
                Already have an account?{" "}
                <NavLink to="/login" className="underline">
                  Login
                </NavLink>{" "}
                and enjoy your favourite shows.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
