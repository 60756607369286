import React from "react";
import "./scss/index.scss";
import { Box, Heading, Link } from "@chakra-ui/react";
import { Btn } from "component/index";
import { logo_white, userPlus, userWhite } from "assets/images/index";
import { NavLink } from "react-router-dom";
export const Header = () => {
  return (
    <Box className="main_Header" width={"100%"} maxWidth="100%">
      <Box
        className="nested_header flex justify-between items-center"
        paddingY={"12px"}
      >
        {/* logo */}
        <Box className="logo_Section flex gap-3 items-center">
          {/* <Heading as={"h1"}>Blokflic</Heading> */}
          <img src={logo_white} alt="logo_white" width={"120px"} />
          <Link to="#">Privacy Policy</Link>
          <Link to="#">Help</Link>
        </Box>
        {/* Buttons */}
        <Box className="header_Buttons flex items-center gap-2">
          <NavLink to={"/login"}>
        <Btn value="Log in" img={userWhite}  />
        </NavLink>
        <NavLink to={"/choose-section"}>
          <Btn
           value="Sign up" img={userPlus}  />
           </NavLink>
        </Box>
      </Box>
    </Box>
  );
};
