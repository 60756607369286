import React, { Fragment,useEffect, useState } from 'react';
import "./scss/index.scss";
import { AdminHeader } from 'layout';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {ugetAllCreatorAction} from "store/actions/adminAction";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#9D0208',
      color: theme.palette.common.white,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  

export const Creators = () => {
  let [limit, setLimit]=useState(10);
  let [skip, setSkip]=useState(0);

  // const limit = 10;
  // let skip = 0;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.getAllCreatorsRed.data); // Access the user object from Redux state
  console.log("Data All Creator",userData);
  const handleNextClick = () => {
    setSkip(skip+limit)
  };
  const handleClickPrev= () => {
    setSkip(skip-limit)
  };
  useEffect(() =>{
    dispatch(ugetAllCreatorAction.getAllCreators(skip, limit))
  },[skip,limit]);
  return (
    <div>
        <AdminHeader/>
        <div className='creator_dashboard'>
        <div className='mt-24 text-white mx-auto nested_creator_dashboard'>
        <h1>Creators</h1>

        {userData == null ? (
          <div className='px-4 py-2'>
            <p>Loading...</p>
          </div>
        ): 
        <>
        {userData.length  == 0 ? "No More User Found" : (
          <div className='table_section'>
          <TableContainer component={Paper}>
        <Table sx={{ minWidth: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Full Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Transaction Hash</StyledTableCell>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Language</StyledTableCell>
              <StyledTableCell>Detials</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell>
                  {row._id}
                </StyledTableCell>                
                <StyledTableCell>
                  {row?.firstName && row?.lastName && `${row.firstName} ${row.lastName}`}
                </StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row?.transactionHash && row?.transactionHash}</StyledTableCell>
                <StyledTableCell>{row?.country && row?.country}</StyledTableCell>
                <StyledTableCell>{row?.countryState && row.countryState}</StyledTableCell>
                <StyledTableCell>{row?.phoneNumber && row?.phoneNumber}</StyledTableCell>
                <StyledTableCell>{row?.language && row?.language}</StyledTableCell>
               <StyledTableCell>
                <NavLink className='button_link cursor-pointer' to={`/creatordetails/${row._id}`}>
                   <span>
                    Details
                    </span>
                   </NavLink>
                </StyledTableCell>
                  
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
          </div>     

        )}
   </>
        
        }

        </div>
        <div className='xl:w-11/12 2xl:w-11/12 lg:w-11/12 md:w-11/12 sm:w-11/12 px-3 my-6 mx-auto flex justify-between items-center max-[650px]:flex-col max-[650px]:gap-3 max-[650px]:px-6'>
        {skip && skip >= 10 ? <button className='bg-[#9D0208] p-3 h-14 w-44 rounded-lg text-white text-lg max-[650px]:w-full' onClick={handleClickPrev}>Prev</button>: <button></button>}
        {userData?.length === 0 ? "" : <button className='bg-[#9D0208] p-3 h-14 w-44 rounded-lg text-white text-lg max-[650px]:w-full' onClick={handleNextClick}>Next</button> } 
        </div>
        </div>

    </div>
  )
}
