import React from "react";
import "./scss/index.scss";
import { Box, Heading } from "@chakra-ui/react";
import { Btn } from "component/index";
import { shopingChart } from "assets/images/index";
import {
  AboutBlokFlic,
  ThreeFactors,
  UploadContent,
  PriceSection,
  PartnerSection
} from "../index";
import { Header, Footer } from "layout/index";
import { NavLink } from "react-router-dom";


export const TopSection = () => {
  return (
    <Box className="landing_Page w-full">
      <Header/>
      <Box className="nested_LandingPage">
        <Box className="sub_NestedPage">
          <Box className="w-full text-white flex flex-col items-center nested_Landing_Content">
            <Heading as="h1">
              Music and Movies, <br />
              Powered by Crypto.
            </Heading>
            <p>
              Find new indie creators and connect with them through <br /> blockchain technology
            </p>
            <Box className="btn_landingPage_top my-6">
              <NavLink to={"/creator-signup"}>
              <Btn value={"Become A Creator"} img={shopingChart} />
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* All Components */}
      <AboutBlokFlic />
      <ThreeFactors />
      <UploadContent />
      <PriceSection />
      <PartnerSection/>
      <Footer/>
    </Box>
  );
};
