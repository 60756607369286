import React,{useState} from 'react';
import "./scss/index.scss";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {RxCross1} from "react-icons/rx";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    height: "600px",
    overflowY: "auto",
    p: 4,
    borderRadois: "8px"
  };
  
export const ModalComp = ({onOpen,onClose, setCheckedItems,setModalOpen, dataArray, title, subTitle}) => {

   console.log("dataArray",dataArray);

  const handleOkClick = () => {
    setCheckedItems(true)
    setModalOpen(false);

  };


  return (
    <div>
    {/* <Button onClick={handleOpen}>Open modal</Button> */}
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={onOpen}
      // onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      
    >
      <Fade in={onOpen}>
        <Box sx={style}>
          <div className='main_Section_modal'>
          <div className='cross_cut' onClick={onClose}>
            <RxCross1/>
            </div>    
          {title &&(
              <Typography id="transition-modal-title" variant="h4" component="h2">
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {subTitle}
          </Typography>
          )}
          <Box my={"20px"} overflow={"auto"}>
            {dataArray.length > 0 && (
              dataArray.map((value) =>{
                return(
                  <Box key={value.id} my={"10px"}>
                    {value.heading && (
                     <Typography id="transition-modal-title" variant="h5" component="h3">
                     {value.heading}
                      </Typography>
                      )}
                     {value.subHeading && (
                     <Typography id="transition-modal-title" variant="h6" component="h5">
                     {value.subHeading}
                      </Typography>
                      )}
                      
                      {value.desc && (
                     <Typography id="transition-modal-title" textAlign={"left"}>
                     {value.desc}
                      </Typography>
                      )}
                  </Box>
                )
              })
            )}
            </Box>
          <Box className='done_btn'>
          <button onClick={handleOkClick}>Submit</button>
          </Box>
          </div>
        </Box>
      </Fade>
    </Modal>
  </div>
  )
}
