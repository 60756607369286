import React from "react";
import "./scss/index.scss";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { aboutBlokflicImg } from "assets/images/index";

export const AboutBlokFlic = () => {
  return (
    <Box className="about_BlokFlic">
      <Flex
        justify={"space-between"}
        align="center"
        className="nested_AboutBlokFlic w-11/12 mx-auto py-12"
      >
        {/* For Content */}
        <Box className="about_BFContent"> 
          <Heading as="h1">Why BLOKFLIC?</Heading>
          <Text>
            Blocflic was built for music and movie enthusiast to connect on a platform that truly beleives in creative and financial independence.  We use public blockchains to connect viewers to creators, and crypto currency to power payments.  Movie watching and music streaming just got better for everyone.
          </Text>
        </Box>
        {/* For Image */}
        <Box className="about_Img">
          <Image src={aboutBlokflicImg} alt="aboutBlokflicImg" />
        </Box>
      </Flex>
    </Box>
  );
};
