import { get_ALLCreator_Constant, GIVE_ACCESS_SUCCESS} from "../constants";


let initialData={
    isloading:false,
    data: null,
}
export const getAllCreatorsReducer=(state=initialData,action)=>{
    console.log("get_Movies_Constant", action);
    switch (action.type) {
        case get_ALLCreator_Constant.GET_SUCCESS:
           return{
            ...state,
            data: action.data,
            isloading: false,
           } 
        default:
            return state;
    }

}
export const giveAccessCreatorReducer=(state=initialData, action)=>{
    console.log("give_Movies_access", action);
    switch(action.type){
     case GIVE_ACCESS_SUCCESS.GIVE_SUCCESS:
        return{
            ...state,
            data: action.data,
            isloading: false
        }
        default:
            return state
    }

}