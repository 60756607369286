import React, { useState } from "react";
import "./scss/index.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalComp } from "layout/modal";
import { Box, Flex, Heading, Checkbox, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/actions";
import {
  userSignIcon,
  userPlusWhite,
  logo_white,
} from "assets/images/index";
import { TextInput, PasswordInput, Btn } from "component/index";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { rulsinfroamtioData, subTitle } from "data";

const slidesData = [
  {
    id: 0,
    text: `The first step to working with BlocFlik is to setup your creator profile and customize your creator page.`,
  },
  {
    id: 1,
    text: `Creators will also need to apply for the BlocFlik creative partner program.  Only approved profiles can upload movies and music to the platform.`,
  },
  {
    id: 2,
    text: `Each application is manually reviewed and will receive a decision within 1 week.  We are looking for motivated and seasoned creators.`,
  },
];

export const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const [modalOpen, setModalOpen] = useState(false);

  const [checkedItems, setCheckedItems] = useState(false);

  const handleOpen = (e) => {
    // setCheckedItems(!checkedItems);
    setModalOpen(true);
    // setCheckedItems(e.target.checked);

  };
  const handleClose = () => {
    setModalOpen(false);
    setCheckedItems(false);
  };

  // validation Schema
  let validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email Format")
      .required("Email Required"),
    password: Yup.string().required("Password Required"),
  });
  // formik
  let formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // agreedTermsAndCond: checkedItems,
      userType: "Creator",
    },
    onSubmit: (values, onSubmitProps) => {
      console.log("checkedItems",checkedItems);
        let resp={
          ...values,
          agreedTermsAndCond: checkedItems
        }
        console.log("resp",resp);
      //  onSubmitProps.setSubmitting(false);
       dispatch(authActions.signup(resp, navigate, toast, onSubmitProps));
    },
    validationSchema,
  });



  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  // console.log(formik);
  // console.log(!formik.isValid);
  return (
    <Box className="main_Signup w-full">
      <Box className="nested_Signup">
        {/* container */}
        <Flex
          className="container_Signup"
          height={"100%"}
          width={"90%"}
          marginX={"auto"}
          justify={"space-between"}
          align="flex-end"
        >
          {/* child one About Company */}
          <Box className="content_section mb-4">
            {/* <h1>hell World</h1> */}
            <Box className="logo">
              {/* <Heading as={"h2"}>Logo</Heading> */}
              <img src={logo_white} alt="logo_white" width={"140px"}  />

            </Box>
            <Box className="silder-Section">
              <Slider {...settings}>
                {slidesData.map((value) => {
                  return (
                    <Box key={value.id}>
                      <Text>{value.text}</Text>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
          {/* form section */}
          <Box className="form_section flex justify-center items-center flex-col">
            <Box>
              <img
                src={userSignIcon}
                alt="userSignIcon"
                width={"165px"}
                height="120px"
              />
            </Box>
            <Flex gap={"2px"}>
              <Heading as={"h2"} className="heading">
                Sign Up
              </Heading>
              <span className="flex items-end pb-1">(For Creator)</span>
            </Flex>
            <form className="form_inputs" onSubmit={formik.handleSubmit}>
              <Box className="my-7">
              <label htmlFor="email">Email</label>
                <TextInput
                  placeholder={"Email"}
                  type="email"
                  value={formik.values.email}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="pl-2">{formik.errors.email}</div>
                ) : // <ErrorMessage name="email">
                //    {(errmsg) =>{
                //     return(
                //       <div>{errmsg}</div>
                //     )
                //    }}
                // </ErrorMessage>
                null}
              </Box>
              <Box className="my-7">
              <label htmlFor="password">Password</label>
                <PasswordInput
                  placeholder={"Password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name="password"
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div  className="pl-2">{formik.errors.password}</div>
                ) : null}
              </Box>

              <Checkbox
                className="check_Box pl-1"
                // onClick={() => setCheckedItems()}
                onChange={handleOpen}
                isChecked={checkedItems}
                name="agreedTermsAndCond"
              >
                Terms and Condition
              </Checkbox>
              {formik.touched.agreedTermsAndCond &&
              formik.errors.agreedTermsAndCond ? (
                <div  className="pl-2">{formik.errors.agreedTermsAndCond}</div>
              ) : null}

              <Box marginY={"20px"} className="signup_Btn flex justify-center">
                <Btn
                  value="Sign up"
                  img={userPlusWhite}
                  disabled={!formik.isValid || formik.isSubmitting}
                />
                {/* Sign up 
                </Btn> */}
              </Box>
            </form>
            <Text textAlign={"center"} paddingX="20px" fontSize={"16px"}>
              Already have an account?{" "}
              <NavLink to="/login" className="underline">
                Login
              </NavLink>{" "}
              and enjoy your favourite shows.
            </Text>
            {/* <Flex
              justify={"center"}
              align="center"
              flexDirection="column"
              className="btn_groups"
              gap={"18px"}
              marginY="12px"
            >
              <Btn img={metamask} />
              <Btn img={googleIcons} bgColor="white" />
            </Flex> */}
          </Box>
        </Flex>
      </Box>
      <ModalComp onOpen={modalOpen} onClose={handleClose} setCheckedItems={setCheckedItems} setModalOpen={setModalOpen} dataArray={rulsinfroamtioData} title={"Blokflic Content and Ownership Policy"} subTitle={subTitle} />

    </Box>
  );
};
