import React from "react";
import "./scss/index.scss";
import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { helpIcons, logo_white, usericon } from "assets/images/index";

export const FormNavbar = () => {
  return (
    <Box className="form_Header">
      <Box className="nested_FormHeader">
        <Box className="form_content w-4/5 mx-auto h-full">
          <Flex justify={"space-between"} align="center" height={"100%"}>
            {/* left side content */}
            <Flex
              justify={"center"}
              align="center"
              gap={"18px"}
              className="logo_Section"
            >
              {/* <Heading as={"h1"} paddingRight="20px">
                BlokFlic
              </Heading> */}
            <img src={logo_white} alt="logo_white" width={"65px"} />

              <Flex gap={"8px"} align="center" cursor={"pointer"}>
                <Text> Discover </Text>
                <MdArrowDropDown fontSize={"24px"} />
              </Flex>
              <Box>
                <img
                  src={helpIcons}
                  className="cursor-pointer"
                  alt="helpIcons"
                  width={"36px"}
                  height="36px"
                />
              </Box>
            </Flex>
            {/* <Box className="user_Details">
              <Flex gap={"18px"} align="center">
                <Text>Carlos Mathews</Text>
                <Image
                  src={usericon}
                  alt="usericon"
                  width={"44px "}
                  height="44px"
                />
              </Flex>
            </Box> */}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
