import React from "react";
import "./scss/index.scss";
import { Box, Text, Heading, Image } from "@chakra-ui/react";
import { theeightytwenty, winnerstribe, damn,royalmoney, levitate, spade, dcg, gsg } from "assets/images/index";

import Slider from "react-slick";


export const PartnerSection = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const partnerImage=[
    {
      id: 1,
      img: royalmoney,
    },
    {
      id: 2,
      img: theeightytwenty,
    },
    {
      id: 3,
      img: damn,
    },
    {
      id: 4,
      img: winnerstribe,
    },
    {
      id: 5,
      img: dcg,
    },
    {
      id: 6,
      img: gsg,
    },
    {
      id: 7,
      img: spade,
    },
    {
      id: 8,
      img: levitate,
    },
  ];


  return <Box className="partner_Section w-full py-16">
    <Box className="nested_PartnerSection">
       <Box>
         <Heading as="h1" paddingY={"18px"}>BLOKFLIC's Partners</Heading>
         <Text paddingBottom={"24px"}>We didn't make it all alone so far here</Text>
       </Box>
       <Box paddingY={"34px"} className="partner_Slider">
           <Slider {...settings} className="flex justify-center items-center">

           {partnerImage.map((currentValue) =>{
              return(
                <Box key={currentValue.id}>
            <Image src={currentValue.img} alt={currentValue.img} width={"220px"}/> 
                </Box>
              )
           })}
          </Slider>
       </Box>
    </Box>
  </Box>;
};
