import { toast } from "react-toastify";
import { uploadMoviesService } from "service/uploadmovies.service";
import { upload_Movies_Constant, get_Movies_Constant } from "store/constants"; 

const uploadMoviesFunc=(data) =>{
    console.log(data)

     return(dispatch)=>{
      uploadMoviesService.uploadMovies(data).then(
          (res) =>{
            console.log(res);
            try{
             if(res.data?.status == 201){
              dispatch({
                type: upload_Movies_Constant.UPLOAD_SUCCESS,
                data: res.data,
              });
                toast.success(res?.data?.message,  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })

             }

            }catch(err){
                // console.log(err);
                if(res.response?.data?.status == 404){
                    toast.error(res.response.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      })
                 }else if(res.response?.data?.status == 400){
                  toast.error(res.response?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })
                 }

      
            }
          },(error) => {
            // alert(error);
               console.log(error);
          }

        ).catch((err) => {
            // toast.err(err);
            console.log(err);
          });
     }


}



const getuploadMoviesFunc=() =>{

   return(dispatch)=>{
    uploadMoviesService.getdMovies().then(
        (res) =>{
          console.log(res);
          try{
           if(res.data?.status == 200){
            dispatch({
              type: get_Movies_Constant.GET_SUCCESS,
              data: res.data,
            });
           }

          }catch(err){
              if(res.response?.data?.status == 404){
                console.log(res.response?.data?.message);
               }else if(res.response?.data?.status == 400){
                console.log(res.response?.data?.message);
               }    
          }
        },(error) => {
          // alert(error);
             console.log(error);
        }

      ).catch((err) => {
          // toast.err(err);
          console.log(err);
        });
   }


}

export const uploadMovieAction={
  uploadMoviesFunc,
  getuploadMoviesFunc
}