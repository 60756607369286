import React, { useState } from "react";
import "./scss/index.scss";
import {
  Box,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
} from "@chakra-ui/react";
import { ImCross } from "react-icons/im";
import { ImCheckmark } from "react-icons/im";

export const PriceSection = () => {
  let [packageChange, setPackageChange] = useState("monthly");

  const onChangePricing = (value) => {
    setPackageChange(value);
  };

  return (
    <Box className="price_Section w-full">
      <Box className="nested_PriceSection">
        <Box className="heading_PriceSection">
          <Heading as={"h1"} paddingY="14px">
            Select Your Plan That Suits You
          </Heading>
          <Text paddingY="14px">
            Only pay gas fees when topping up your streaming account. Pay as you
            stream or mint your membership NFT.
          </Text>
          <Text>Membership ends when NFT expires or account reaches zero.</Text>
          <Box className="flex justify-center gap-4 my-4 mt-8 items-center text-xl">
            <Box
              className="underline cursor-pointer"
              onClick={() => onChangePricing("monthly")}
            >
              Check Out Months Plan
            </Box>
            /
            <Box
              className="underline cursor-pointer"
              onClick={() => onChangePricing("yearly")}
            >
              Check Out 12 Months Plan
            </Box>
          </Box>
        </Box>

        {/* Table Section */}
        <Box className="package_section">
          {packageChange == "monthly" ? (
            <Box className="table_PriceSection my-10 mx-auto">
              <TableContainer width={"100%"} padding="20px">
                <Table variant="simple" width={"100%"}>
                  <Thead>
                    <Tr>
                      <Th>Features</Th>
                      <Th>Silver</Th>
                      <Th>
                        Golden <span>(Most Popular)</span>{" "}
                      </Th>
                      <Th>Platinium</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr className="price_section">
                      <Td>NFT Membership</Td>
                      <Td>$20 USD/mo.</Td>
                      <Td>$40 USD/3mo.</Td>
                      <Td>$60 USD/6mo.</Td>
                    </Tr>
                    <Tr>
                      <Td>Access to Movie and Music Library</Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Access to Live Streaming Events</Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Interactive Rooms</Td>
                      <Td>
                        <ImCross />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Affiliate Program</Td>
                      <Td>
                        <ImCross />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Bonus for Renewing Membership</Td>
                      <Td>
                        <ImCross />
                      </Td>
                      <Td>
                        <ImCross />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Special Screening Invites</Td>
                      <Td>
                        <ImCross />
                      </Td>
                      <Td>
                        <ImCross />
                      </Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box className="table_yearly my-10 mx-auto">
              <TableContainer width={"100%"} padding="20px">
                <Table variant="simple" width={"100%"}>
                  <Thead>
                    <Tr>
                      <Th>Features</Th>
                      <Th>
                        Diamond <span className="invisible">(Most Popular)</span>{" "}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr className="price_section">
                      <Td>NFT Membership</Td>
                      <Td>$20 USD/mo.</Td>
                    </Tr>
                    <Tr>
                      <Td>Access to Movie and Music Library</Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Access to Live Streaming Events</Td>
                      <Td>
                        <ImCheckmark />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Interactive Rooms</Td>
                      <Td>
                        <ImCross />
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>Affiliate Program</Td>
                      <Td>
                        <ImCross />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Bonus for Renewing Membership</Td>
                      <Td>
                        <ImCross />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Special Screening Invites</Td>
                      <Td>
                        <ImCross />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
