import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import "./scss/index.scss";
import {
  Box,
  Heading,
} from "@chakra-ui/react";
import {PasswordInput, Btn} from "component/index";
import { authActions } from "store/actions";
import {useNavigate} from "react-router-dom"

export const ResetPassword = () => {

  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [password, setPassword]=useState("");
  const [confirmPassword, setConfirmPassword]=useState("");
   const [confrimation, setConfirmation]=useState("")

  const onChangeFuncPass=(e) =>{

    setConfirmPassword(e.target.value)
  }
   useEffect(() =>{
    console.log(confirmPassword)
    if(password === confirmPassword){
      setConfirmation("Password is correct")
    }else{
      setConfirmation("Password is incorrect")
    }
   },[confirmPassword]);
    
  const onSubmitFunc=(e) =>{
     e.preventDefault();
    const req={
      email: localStorage.getItem("emailForgetPass"),
      otpKey:localStorage.getItem("otpkeyForgetPass"),
      password:password,
      confirm_password:confirmPassword
    }
    console.log(req)
    dispatch(authActions.resetPasswordFunc(req, navigate))
  } 

  return (
    <div className='resetpass_Section'>
      <div className="nested_resetpass">
      <Box className="container_forgPassSection  flex justify-center items-center flex-col">
          <Box className="main_Heading text-center">
            <Heading as={"h1"}>Forget Password</Heading>
          </Box>
          {/* Forget password Section */}
          <Box className='forgetSection'>
           <form onSubmit={onSubmitFunc}>
            <Box>
                <PasswordInput placeholder='create new password' name='password' onChange={(e) => setPassword(e.target.value)} /> 
                </Box>
                <Box className='confirmed'>
                <PasswordInput placeholder='confirm new password' name='confirm_password' onChange={onChangeFuncPass} /> 
                <span>{confirmPassword && confrimation}</span>
                </Box>
                <Box marginTop={"12px"}>
                   <Btn value='Reset New Password' disabled={password == "" || password !== confirmPassword}/>
                </Box>
           </form>
          </Box>
   
        </Box>
      </div>
    </div>
  )
}
