import React from 'react';
import "./scss/index.scss";
import { AdminHeader } from 'layout';
import { useParams } from 'react-router-dom';
import {data} from "../../../data"

export const NestedPage = () => {
    const { id } = useParams();
      //  console.log(data);
      let getFilterData=data.filter(value =>{
          return value.id == id
      });
      console.log(getFilterData);
  return (
    <div>
    <AdminHeader/>
    <div className='nested_detail_page'>
    <div className='detail_div'>
    <h2>Dynamic Page {id}</h2>
    {getFilterData.map(value =>{
      return(
        <div key={value.id}>
          {value.fullName}
        </div>
      )
    })}
    {/* <h1>Users</h1> */}
    </div>
    </div>
    </div>
  )
}
