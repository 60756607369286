import React, {useState} from "react";
import "./scss/index.scss";
import { Box, Flex, Heading, FormControl, Text, Link } from "@chakra-ui/react";
import { TextInput, Btn } from "component/index";
import Slider from "react-slick";
import { FaUser } from "react-icons/fa";
import { NavLink , useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "store/actions";
import { logo_white } from "assets/images";


const slidesData = [
  {
    id: 0,
    text: `Lorem ipsum, dolor sit amet consectetur adipisicing elit.
      Aspernatur odit expedita voluptatibus consectetur ipsam
      asperiores in, exercitationem, earum voluptatem rem
      consequatur iure libero incidunt adipisci pariatur
      molestias? Voluptatem, minima dolores.`,
  },
  {
    id: 1,
    text: `Short stories have a way of teaching lessons that makes them
      more relatable and interesting. Rather than just telling
      your kid not to lie, relating a short story about it helps
      them understand what happens when they lie.`,
  },
  {
    id: 2,
    text: `There was once a hare who was friends with a tortoise. One
      day, he challenged the tortoise to a race. Seeing how slow
      the tortoise was going, the hare thought he’ll win this
      easily. So he took a nap while the tortoise kept on going.
      When the hare woke up.`,
  },
];

export const ForgetPassword = () => {
   const dispatch=useDispatch();
   const navigate=useNavigate();
   const [email, setEmail]=useState("");


  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  const onSubmitFunc=(event) =>{
    event.preventDefault()
    let resObject={
      email: email
    }
    dispatch(authActions.forgetPassword(resObject, navigate))
  console.log(resObject)
  }

  return (
    <Box className="forget_Pwd w-full">
      <Box className="nested_Pwd">
        {/* container */}
        <Flex
          className="container_Pwd"
          height={"100%"}
          width={"90%"}
          marginX={"auto"}
          justify={"left"}
          align="flex-end"
          columnGap={"24px"}
        >
          {/* form section */}
          <Box className="form_section flex justify-center items-center flex-col">
            <Box>
              {/* <img
              src={userSignIcon}
              alt="userSignIcon"
              width={"165px"}
              height="120px"
            /> */}
              <FaUser fontSize={"124px"} />
            </Box>
            <Box paddingY={"12px"}>
              <Heading as={"h2"} className="heading">
                Forget Password
              </Heading>
            </Box>
            <form className="form_inputs" onSubmit={onSubmitFunc}>
              <Box className="my-7">
                <label className="px-3 text-xl">Email</label>
                <TextInput placeholder={"Enter Your Email"} type="email"  onChange={(e) => setEmail(e.target.value)} />
              </Box>
              <Text textAlign={"left"} paddingY="2px" paddingRight={"18px"} fontSize={"18px"}>
              Would you like to {" "}
              <NavLink  to="/login" className="underline">
                 Log in?
              </NavLink>{" "}
            </Text>
              <Box
                marginY={"20px"}
                className="forgetPwd_Btn flex justify-center"
              >
                <Btn value={"Submit"} disabled={email === ""} />
              </Box>
            </form>
          </Box>

          {/* child one About Company */}
          <Box className="content_section mb-4">
            {/* <h1>hell World</h1> */}
            <Box className="logo">
              <img src={logo_white} alt="logo_white" width={"140px"}  />
            </Box>
            <Box className="silder-SectionforgotPwd">
              <Slider {...settings}>
                {slidesData.map((value) => {
                  return (
                    <Box key={value.id}>
                      <Text>{value.text}</Text>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
