import React from "react";
import "./scss/index.scss";
import { Box, Text, Image, Heading } from "@chakra-ui/react";
import Slider from "react-slick";
import { ehterIcon, factThree, factTwo } from "assets/images/index";

const data = [
  {
    id: 1,
    img: ehterIcon ,
    heading: "Creators",
    text: `Blokflic is interested in working with seasoned creators so that our viewers can count on great independent content.  This is why creators who want to work with Blokflic must apply. `,
  },
  {
    id: 2,
    img:  factTwo ,
    heading: "Viewers",
    text: `Viewers can stream movies and music from Blokflic creators and interact with them in special live rooms.  Viewers can also mint NFTs from the creators they like and trade media in the open market.`,
  },
  {
    id: 3,
    img:  factThree ,
    heading: "Payments",
    text: `Viewers can purchase credits to stream, or mint a monthly membership NFT for unlimited access to content.  Blocflick is powered by Ethereum, Binance Chain, and Polygon networks.`,
  },
  {
    id: 4,
    img:factTwo ,
    heading: "Ecosystem",
    text: `Blokflic is providing media streaming services and is not a crypto company.  We just use blockchain and crypto to pay our our creators because it makes sense.`,
  },
];

export const ThreeFactors = () => {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,

        },
      },
    ],
  };

  return (
    <Box className="threeFactor pt-24">
      <Box className="nested_threeFactor">
        <Box className="carosal_ThreeFactors  py-6">
          <Slider {...settings}>
            {data.map((value) => {
              return (
                <Box className="carosal_child" key={value.id}>
                  <Box paddingTop="28px" display={"flex"} justifyContent="center">
                    <Image src={value.img} alt="ehterIcon" width={"200px"} />
                  </Box>
                  <Box>
                    <Heading as={"h1"} paddingY="18px">
                      {value.heading}
                    </Heading>
                    <Text padding={"18px 14px"}>{value.text}</Text>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};
