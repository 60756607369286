import React, { useState, useEffect } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import { Btn } from "component/index";
import ErrorMessage from "./ErrorMessage";
import "./scss/index.scss";
import { parseEther } from "ethers";
import { Box } from "@chakra-ui/react";
import { useContract } from "../../../../../hooks/index";
import { Library } from "assets/images";
// lib/utils
function SwitchModel({
  DollarPrice,
  handleNetwork,
  selectedNetwork,
  allNetworkPricesInDollar,
  setInput,
  input,
  hash,
  setHash
}) {
  let [error, setError] = useState();
  let { active, chainId, activate, account, library } = useWeb3React();
  let contract = useContract();
  let injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137],
  });
  console.log("contract", contract);

  var metamask = async () => {
    try {
      await handleNetwork(selectedNetwork);
      await activate(injected);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("Library:", library);
  console.log("Account:", account, allNetworkPricesInDollar);
  console.log("ChainId:", chainId);

  let payToken = async () => {
    // console.log("Dollar", parseEther(DollarPrice.toString()).toString());
    try {
      if (contract) {
        await contract
          .buyGS50(0, {
            value: `${parseEther(DollarPrice.toString()).toString()}`,
          })
          .then((txHash) => {
            console.log({
              DollarPrice,
              selectedNetwork,
              allNetworkPricesInDollar,
              txHash,
            });
            setInput({
              ...input,
              cryptoMehtod: selectedNetwork,
              transactionHash: txHash.hash,
              walletAmount: DollarPrice.toString(),
            });
            setHash(txHash.hash)
          })
          .catch((error) => console.error("Error Pay Token", error));
      } else {
        console.log(contract);
        console.log("Contract not available. Check if Web3React is connected.");
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log("active", active);

  return (
    <>
      {/* {!contract && ( */}
      <>
        {!active ? (
          <Box marginY={"14px"} marginX="4px" className="submti_Btn">
            <Btn
              value="Connect"
              padding="14px"
              bgColor="white"
              onClick={metamask}
            />
          </Box>
        ) : (
          <Box marginY={"14px"} marginX="4px" className="submti_Btn">
            {hash ? "" : (
                  <Btn
                  value="Pay"
                  padding="14px"
                  bgColor="white"
                  onClick={payToken}
                />
            )}
          </Box>
        )}
        <ErrorMessage message={error} />
      </>
      {/* )} */}
    </>
  );
}

export default SwitchModel;
