export const data=[
    {
        id:1,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:2,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:3,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:4,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:5,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:6,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:7,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:8,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:9,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:10,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
  ]

export const rulsinfroamtioData=[
    {
        id:1,
        heading: "1. Content Policy:",
        subHeading: "1.1. Safety & Respect:",
        desc: "Our foremost priority is ensuring that the content on Blokflic does not harm the health, safety, or well-being of our users or the broader community. Therefore, content that promotes violence, self-harm, dangerous activities, or hate speech is strictly prohibited. We strive to maintain a platform that is inclusive, respectful, and free from any form of discrimination or threats to individuals or groups."
    },
    {
        id:2,
        subHeading: "1.2. Copyright:",
        desc: "Blokflic respects intellectual property rights and copyrights. Creators must ensure that they have the necessary rights or permissions to share any content on our platform. Unauthorized distribution of copyrighted material is not allowed."
    },
    {
        id:3,
        subHeading: "1.3. Exclusive Release:",
        desc: "To create a unique and engaging streaming environment, content shared on Blokflic must be exclusive to our platform during its availability here. Creators agree not to simultaneously release the same content on other platforms."
    },
    {
        id:4,
        heading: "2. Ownership Rights:",
        subHeading: "2.1. Creator Ownership:",
        desc: "Creators retain 100% ownership rights to their media shared on Blokflic. Your creative work remains solely yours, and we deeply appreciate the trust you place in us to share it with our community."
    },
    {
        id:5,
        subHeading: "2.2. Licensing Rights:",
        desc:"By sharing your content on Blokflic, you grant Blokflic a non-exclusive license to distribute your content publicly on our platform. This licensing allows us to provide your content to our audience while respecting your rights to share your work elsewhere."
    },
    {
        id:6,
        heading: "3. Join the Conversation:",
        subHeading: "3.1. Engage with Us:",
        desc:"We invite all creators and users to join the conversation and connect with our social media sites. Your participation is vital in shaping our platform and making it even more appealing and interactive. Share your thoughts, ideas, and feedback with us."
    },
    {
        id:7,
        heading: "4. Respecting Creator Rights:",
        subHeading: "4.1. No Unauthorized Use:",
        desc:"Blokflic and its partners commit to using your content only for its intended distribution on our platform. We respect your ownership rights and will not engage in unauthorized use, alteration, or distribution of your content."
    },
    {
        id:8,
        heading: "5. Transparency and Fair Practices:",
        subHeading: "5.1. Transparency:",
        desc:"We are committed to being transparent and maintaining fair practices. If you have any concerns about how your content is being used or any other aspect of our platform, please do not hesitate to reach out to us. We value open communication and aim to address any issues promptly."
    },
    {
        id:9,
        heading: "6. Content Removal Request:",
        subHeading: "6.1. Request for Removal:",
        desc:"Creators have the right to request the removal of their content from Blokflic at any time. We will promptly respond to such requests, and your content will be removed from our platform in accordance with your wishes."
    },
    {
        id:10,
        heading: "7. Continuous Support:",
        subHeading: "7.1. Support for Creators:",
        desc:"Blokflic is dedicated to supporting creators and their creative endeavors. We provide assistance, guidance, and resources to help you succeed on our platform. If you have any questions, need assistance, or seek guidance, our support team is here to help."
    },
    {
        id:11,
        heading: "8. Cryptocurrency Payments for Media Consumption:",
        subHeading: "8.1. Crypto Payments:",
        desc:"Blokflic is dedicated to supporting creators and their creative endeavors. We provide assistance, guidance, and resources to help you succeed on our platform. If you have any questions, need assistance, or seek guidance, our support team is here to help."
    },
    {
        id:12,
        subHeading: "8.2. Reclaiming Native Network Tokens:",
        desc: "To access the value represented by GS50, creators will have the option to sell GS50 tokens on the respective native networks where they are available. This process allows creators to convert GS50 into their preferred network's native token."
    },
    {
        id:13,
        desc: "For more information about GS50 and the cryptocurrency payment system, please visit [www.the8020.org](www.the8020.org)."
    },
    {
        id:14,
        desc: "This comprehensive policy reflects our unwavering commitment to embracing innovative technologies, fostering a creative and supportive community, and providing creators with flexibility and options for monetizing their content. Blokflic remains dedicated to empowering creators and delivering a diverse range of quality media content to our valued users. We appreciate your continued support and participation in our platform."
    },
];
export const subTitle="At Blokflic, we are committed to providing a safe, enjoyable, and innovative streaming experience while respecting the rights of content creators. Our comprehensive Content and Ownership Policy outlines the rules, guidelines, and principles for content, licensing, and ownership when using our platform:"
