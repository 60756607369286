import React, { useState } from "react";
import "./scss/index.scss";
import { TextInput, Btn } from "component/index";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { rightArrow } from "assets/images/index";

export const StepTwo = ({ addNumber, setAddNumber,onChangeportfolio, onchangeInput }) => {
  let [socialCounter, setSocialCounter] = useState(0);
  let [mediaPortfolio, setMediaPortfolio] = useState(0);

  const incrementSocialMedia = (e) => {
    e.preventDefault();
    setSocialCounter(socialCounter + 1);
  };
  const incrementMediaPortfolio = (e) => {
    e.preventDefault();
    setMediaPortfolio(mediaPortfolio + 1);
  };
  const onSubmitFunc=(e)=>{
    e.preventDefault();
    setAddNumber(addNumber + 1)
  }

  return (
    <Box className="steptwo_Form">
      <Flex justify={"flex-start"} className="nested_Steptwo py-6 px-5">
        {/* From _Section  */}
        <Box>
          <Heading as={"h1"}>Portfolio Information</Heading>
          <form className="form_Steptwo py-2" onSubmit={onSubmitFunc}>
            <Box className="box_section_two">
            <Box paddingY={"12px"} className="from_child">
              <FormLabel paddingY={"8px"} paddingX="14px">
                Soical Media
              </FormLabel>
              <TextInput
                type="text"
                placeholder="Social Media 1 (Compulsory)"
                name="socialMediaOne"
                required={true}
                // value={input.socialMediaOne}
                onChange={(e) => onchangeInput(e,0)}
              />
              <TextInput
                type="text"
                placeholder="Social Media 2 (optional)"
                name="socialMediaTwo"
                // value={input.socialMediaTwo}
                onChange={(e) => onchangeInput(e, 1)}

              />
              {Array.from(Array(socialCounter)).map((currentValue, index) => {
                // console.log(socialCounter);
                return (
                  <TextInput
                    key={index}
                    id={index.toString()}
                    type="text"
                    // value={input.socialMediaTwo}
                    placeholder={`Social Media ${index + 3} (optional)`}
                    onChange={(e) => onchangeInput(e,index+2)}
                    />
                );
              })}
              <Box marginY={"14px"} marginX="4px">
                <Btn
                  value="Add More +"
                  padding="8px"
                  onClick={incrementSocialMedia}
                />
              </Box>
            </Box>

            
            <Box paddingY={"12px"} className="from_child">
              <FormLabel paddingY={"8px"} paddingX="14px">
                Media Portfolio
              </FormLabel>
              <TextInput
                type="text"
                placeholder="Media Portfolio 1 (Compulsory)"
                name="mediaPortfolioOne"
                // value={input.mediaPortfolioOne}
                onChange={(e) => onChangeportfolio(e, 0)}
                required={true}

                />
              <TextInput
                type="text"
                placeholder="Media Portfolio 2 (optional)"
              
                name="mediaPortfolioTwo"
                // value={input.mediaPortfolioTwo}
                onChange={(e) => onChangeportfolio(e, 1)}

              />
              {Array.from(Array(mediaPortfolio)).map((currentValue, index) => {
                return (
                  <TextInput
                    key={index}
                    id={index.toString()}
                    type="text"
                    // value={input.mediaPortfolioTwo}
                    placeholder={`Social Media ${index + 3} (optional)`}
                    onChange={(e) => onChangeportfolio(e,index+2)}
                  />
                );
              })}

              <Box marginY={"14px"} marginX="4px">
                <Btn
                  value="Add More +"
                  padding="8px"
                  onClick={incrementMediaPortfolio}
                />
              </Box>
            </Box>
            </Box>


            <Box marginY={"14px"} marginX="4px">
              <Btn
                img={rightArrow}
                value="Next"
                padding="8px"
                bgColor="white"
                // onClick={() => setAddNumber(addNumber + 1)}
              />
            </Box>
          </form>
        </Box>
        {/* Stepper Section */}
        {/* <Box className="desc_steptwo">
          <Flex
            gap={"28px"}
            justify="center"
            alignItems="center"
            height={"100%"}
          >
            <Flex
              direction={"column"}
              className="steppers_line"
              justify="space-between"
              height={"40vh"}
              position="relative"
            >
              <Flex
                position={"absolute"}
                left="0px"
                direction={"column"}
                className="stepper_boxes"
                justify="space-between"
                height={"40vh"}
              >
                <Box></Box>
                <Box></Box>
              </Flex>
              <Text>
                Fill out the name that will be visible to you and us, please
                only use alphabets and numeric values and avoid any special
                characters like @, #, $, %, !, ^, &.
              </Text>
              <Text>
                Please Add your Wallet Address & Country, the state field will
                be unlocked as you choose your Country. Please use the correct
                format for the phone number (no need to mention country code
                while entrying your phone number)
              </Text>
            </Flex>
          </Flex>
        </Box> */}
      </Flex>
    </Box>
  );
};
