import React, { useContext } from "react";
import "./scss/index.scss";
import { FormData } from "../index";
import { BsUpload } from "react-icons/bs";
import { Form, Formik } from "formik";

export const Confirmation = () => {
  let { setActiveState, onSubmitFunc,submitDetails } = useContext(FormData);
  console.log("initalValues", submitDetails);
  const {description, movieTitle,director , selectionGenre, stars, writers, genre}=submitDetails;

  return (
    <div className="confirmation_fields">
      <div className="nested_confirmation_field">
        <div className="heading_section">
          <h1>Confirmation</h1>
          <p>
            If you want to change in these which you provided you need to click on Edit
            button and give the changes.
          </p>
        </div>
        <div className="flex gap-6 my-5  confrimation_form">
          <div className="form_details">
            {/* Media Title */}
            <div className="py-2">
              <p className="label_section">Media Title</p>
              {movieTitle && (
              <div className="main_content">
              <p>{movieTitle}</p>
            </div>
              )}

            </div>
            {/* Descrtiption */}
            <div className="py-2">
              <p className="label_section">Description</p>
              {description && (
            <div className="main_content">
              <p>{description}</p>
            </div>
              )}
            </div>
            {/* Genre */}
            <div className="py-2">
              <p className="label_section">Genre</p>
              <div className="flex gap-3 flex-wrap items-center">
               {selectionGenre.map((value, ind) =>{
                 return(
                  <div className="main_content" key={ind}>
                  <p>{value}</p>
                </div>
                 )                
               })} 
               {genre && (
                <>
                {genre.map((value, ind) =>{
                  return(
                   <div className="main_content" key={ind}>
                   <p>{value}</p>
                 </div>
                  )                
                })}
                </>
               )}

                {/* <div className="main_content">
                  <p>Lorem ipsum.</p>
                </div>
                <div className="main_content">
                  <p>Lorem ipsum.</p>
                </div> */}
              </div>
            </div>
            {/* Dreictor */}
            <div className="py-2">
              <p className="label_section">Director</p>
              {director &&(
              <div className="main_content">
              <p>{director}</p>
            </div>
              )}
            </div>
            {/* writer */}
            <div className="py-2">
              <p className="label_section">Writer</p>
              {writers &&(
              <div className="main_content">
              <p>{writers}</p>
            </div>
              )}

            </div>
            {/* stars */}
            <div className="py-2">
              <p className="label_section">Stars</p>
              <div className="flex flex-wrap gap-3 items-center">
                {stars && (
                <div className="main_content">
                <p>{stars}</p>
              </div>
                )}
              </div>
            </div>
            {/* video fielname */}
            {/* <div className="py-2">
              <p className="label_section">File</p>
              <div className="pl-2">
                <p className="flex gap-2 items-center underline">
                  <BsUpload />
                  BlackAdam720px.mp4
                </p>
              </div>
            </div> */}
          </div>
          <div className="img_Section"></div>
        </div>
      </div>
      <div className="submit_section_btn mt-4">
        <div className="submit_btn flex items-center gap-4">
          <button
            className="flex items-center gap-2 justify-center"
            onClick={() => setActiveState(1)}
          >
            Back
          </button>
          {/* <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={initalValues}
          >
            <Form> */}
            <form onSubmit={onSubmitFunc}>
              <button className="flex items-center gap-2 justify-center">
                {" "}
                Submit
              </button>
              </form>
            {/* </Form>
          </Formik> */}
        </div>
      </div>
    </div>
  );
};
