import React, {useState,useEffect, useLayoutEffect} from 'react';
import "./scss/index.scss";
import { IconDropleft, Rico2, logo_white } from 'assets/images';
import { TextInput, Btn } from 'component';
import {HiSearch} from "react-icons/hi";
import {FaUserCircle} from "react-icons/fa";
import { useSelector } from 'react-redux';



export const CreatorProfileHeader = () => {
  const userData = useSelector((state) => state.authentication.data); // Access the user object from Redux state
   console.log("Header",userData);
   let [headerData, setHeaderData]=useState({});
   useEffect(() =>{
      if(userData?.data){
        setHeaderData(userData?.data);
      }else{
        let convertData=JSON.parse(localStorage.getItem("auth"));
        // console.log(convertParse);  
        setHeaderData(convertData?.data);
      }
  },[]);
  return (
    <div className='creator_header_section'>
      <div className="nested_Creator_header_section">
        <div className="logo_section">
           {/* <img src={blocflik_logo} alt="blocflik_logo" />  */}
           {/* <h1>BLOKFLIC</h1> */}
           <img src={logo_white} alt="logo_white" width={"70px"} />
           <p className='flex items-center gap-2 cursor-pointer'>Discover   
           <img src={IconDropleft} alt="IconDropleft" width={"14px"} height="14px" />
           </p>
        </div> 
        <div className="search_Media">
            <div className="child_search flex items-center ">
               <TextInput placeholder='Search across your channel!'/> 
               <Btn icon={HiSearch} /> 
            </div>
        </div>
        <div className="creator_name flex items-center gap-2">
        <p>{headerData?.firstName && headerData?.lastName && `${headerData?.firstName} ${headerData?.lastName}`}</p> 
         {/* <img src={Rico2} alt="Rico2" /> */}
         <FaUserCircle/>
        </div>
      </div>
    </div>
  )
}
// const mapStateToProps = (state) => ({
//   user: state.authentication.data,
// });

// export const CreatorProfileHeader = connect(mapStateToProps)(CreatorProfileHeaders);
