import React, { useContext, useState, ref, useRef, useEffect } from "react";
import "./scss/index.scss";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
// import { InfoMessage } from "component";
import { arrowblackright } from "assets/images";
import { FormData } from "../index";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const steps = [
  {
    label: "Movie Title",
    description: `Fill out the name that will be visible to you and us, please only use alphabets and numeric values and avoid any special characters like @, #, $, %, !, ^, &.`,
  },
  {
    label: "Description",
    description:
      "Let us know about your media, what is it like, time duration, etc. make sure the word limit is 1000.",
  },
  {
    label: "Genre",
    description: `Select the genre that perfectly defines the media, you can choose as many genre as you want`,
  },
  {
    label: "Director",
    description: `Provide the names of Director, Writers, and Stars (Optional)`,
  },
  // {
  //   label: "ulpoad Tubmail",
  //   description: `Upload movie cover, file should be in JPG or PNG format, size of file should be less then 3mb`,
  // },
];

export const MediaDetails = () => {
  const [activeStep, setActiveStep] = useState(0);

  let {
    setActiveState,
    getUpdateState,
    setSubmitDetails,
    submitDetails,
    setSelectedImage,
    selectedImage,
  } = useContext(FormData);

   const {imageUpload}=submitDetails;
  
  // initail values
  // const initalValues = {
  //   movieTitle: "",
  //   description: "",
  //   selectionGenre: [],
  //   genre: [],
  //   director: "",
  //   writers: "",
  //   stars: "",
  //   // uploadCoverUrl: "",
  //   // uploadMovieUrl: "",
  //   // adultContent: false,
  // };
  
// form validate Schemea
  const validationSchema = Yup.object({
    movieTitle: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    selectionGenre: Yup.array().required("Required"),
    director: Yup.string().required("Required"),
    writers: Yup.string().required("Required"),
    stars: Yup.string().required("Required"),
    // uploadCoverUrl: Yup.string().required(
    //   "only png, jpeg, jpg and gif will accept"
    // ),
    // uploadMovieUrl: Yup.string().required("Required!"),
  });

  const handleNext = (ind) => {
    setActiveStep(ind);
  };

  // in the genre we are manually doing error
  let genreeArrayValidation = (value) => {
    // console.log(value);
    let error;
    if (!value) {
      // console.log(value);
      error = "Reqired";
    }
    return error;
  };

    
  const handleFileInputChange=(e,funcField)=>{
    console.log("Target", e.target.files);
    // setSubmitDetails({...submitDetails,uploadImage:e.target.files[0]})
    // setSelectedImage(e.target.files[0])
    const file = e.target.files;

    // Check if a file is selected
    if (file.length > 0) {
      // const imagePath = URL.createObjectURL(file);
      // console.log(imagePath);
      
      // // Update the 'imageUpload' field while keeping other properties unchanged
      // // setSelectedImage(imagePath)
      setSubmitDetails({...submitDetails});
      
    //  funcField("imageUpload",[...e.target.files])

    }
    // console.log(submitDetails);

  }
 
  //   useEffect(() =>{
  //   console.log('Image Upload Value Changed:', imageUpload);
  //   // console.log("Values", submitDetails);
  //   // console.log(values);
  //   // setSubmitDetails(values)
    
  //   // setSubmitDetails((prevState) => ({
  //   //   ...prevState,
  //   //   imageUpload: imagePath,
  //   // }));
  // },[imageUpload]);
  const onSubmit = (values) => {
    console.log("Submit",submitDetails);
    console.log("Values",values);
    // if(submitDetails.imageUpload != null){
      setSubmitDetails(values);
      setActiveState(1);

    // }

    // getUpdateState()
  };

  const pushInput=(e, push)=>{

  }

  return (
    <div className="media_details_section">
      <div className="heading_media_details">
        <h1 className="text-white text-4xl overflow-y-hidden p-4 px-7">
          About Movie
        </h1>
      </div>
      {/* section form  */}

      <div className="medai_details_section_from">
        <Formik
          initialValues={submitDetails}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors, setFieldValue }) => {
            console.log(errors);
            return (
              <Form>
                <div className="media_details_content flex gap-3">
                  <div className="section_form w-2/4">
                    <div className="form_control">
                      <label htmlFor="movieTitle">Movie Title</label>
                      <div className="form_control_div">
                        <Field
                          type="text"
                          id="movieTitle"
                          name="movieTitle"
                          autoComplete="off"
                        />
                      </div>
                      <ErrorMessage name="movieTitle">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="form_control">
                      <label htmlFor="description">Description</label>
                      <div className="form_control_div">
                        <Field
                          as="textarea"
                          id="description"
                          name="description"
                        />
                      </div>
                      <ErrorMessage name="description">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="form_control">
                      <label htmlFor="genre">Genre</label>
                      <div className="flex gap-3 items-center w-full">
                        <div className="form_control_div w-full">
                          <Field
                            name="selectionGenre[0]"
                            id="selectionGenre"
                            as="select"
                            validate={genreeArrayValidation}
                            className="selection_box"
                          >
                            <option value=""></option>
                            <option value="adventure">Adventure</option>
                            <option value="horrorone">horrorone</option>
                            <option value="horrortwo">horrortwo</option>
                            <option value="horrorthree">horrorthree</option>
                            <option value="horrorfour">horrorfour</option>
                          </Field>
                        </div>
                        <div className="form_control_div w-full">
                          <Field
                            name="selectionGenre[1]"
                            id="selectionGenre"
                            as="select"
                            className="selection_box"
                          >
                            <option value=""></option>
                            <option value="adventure">Adventure</option>
                            <option value="horrorone">horrorone</option>
                            <option value="horrortwo">horrortwo</option>
                            <option value="horrorthree">horrorthree</option>
                            <option value="horrorfour">horrorfour</option>
                          </Field>
                        </div>
                      </div>
                      <ErrorMessage name="selectionGenre">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                      <FieldArray name="genre" className="w-full">
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { genre } = values;
                          // console.log(form.errors);
                          return (
                            <div>
                              {genre.map((values, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form_control_div">
                                      <Field
                                        name={`genre[${index}]`}
                                        autoComplete="off"
                                      />
                                    </div>
                                    {genre.length > 0 && (
                                      <button onClick={() => remove(index)}>
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                );
                              })}
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  push();
                                }}
                                className="addButtongenre"
                              >
                                Add More Genre+
                              </button>
                            </div>
                          );
                        }}
                      </FieldArray>
                    </div>
                    <div className="form_control">
                      <label htmlFor="director">Director</label>
                      <div className="form_control_div">
                        <Field
                          type="text"
                          id="director"
                          name="director"
                          autoComplete="off"
                        />
                      </div>
                      <ErrorMessage name="director">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="form_control">
                      <label htmlFor="writers">Writers</label>
                      <div className="form_control_div">
                        <Field
                          type="text"
                          id="writers"
                          name="writers"
                          autoComplete="off"
                        />
                      </div>
                      <ErrorMessage name="writers">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="form_control">
                      <label htmlFor="stars">Stars</label>
                      <div className="form_control_div">
                        <Field
                          type="text"
                          id="stars"
                          name="stars"
                          autoComplete="off"
                        />
                      </div>
                      <ErrorMessage name="stars">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                    </div>
                    {/* <div className="form_control upload_image">
                      <label htmlFor="imageUpload">Upload Cover</label>
                      <Field
                        type="file"
                        id="imageUpload"
                        name="imageUpload"
                        // onChange={handleFileInputChange}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        className="pl-2"
                        
                      />
                      <ErrorMessage name="uploadCoverUrl">
                        {(errorMsg) => <div className="error">{errorMsg}</div>}
                      </ErrorMessage>
                    </div> */}
                  </div>
                  <div className="stepper_media_details w-2/4">
                    {/* <h1>Hello World</h1> */}
                    <Box>
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step key={step.label} className="flex flex-col">
                            <StepLabel
                              className="cursor-pointer stepper_circle"
                              onClick={() => handleNext(index)}
                            >
                              {step.label} (instructions)
                            </StepLabel>

                            <StepContent>
                              {/* <StepContent> */}
                              <Typography>{step.description}</Typography>
                              {/* </StepContent> */}
                              {/* <Typography>{step.description}</Typography> */}
                              {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
                    </Box>
                  </div>
                </div>
                <div className="next_section_btn mt-4">
                  <div className="next_btn_first_step">
                    <button className="flex items-center gap-2 justify-center" type="submit">
                      {" "}
                      <img src={arrowblackright} alt="arrowblackright" /> Next
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
