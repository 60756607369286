import React from "react";
import "./scss/index.scss";
import { Btn } from "component/index";
import { Box, Text, Image, Heading, Link } from "@chakra-ui/react";
import {
  marketPlace,
  Subcription2,
  Subcription4,
  shadowbg,
  CreatorBtn,
} from "assets/images/index";

export const UploadContent = () => {
  return (
    <Box className="uploadcontent_section w-full pt-24">
      <Box className="nested_uploadcontent">
        <Box>
          <Heading as={"h1"}>Approved creators can upload content</Heading>
          <Text>Get paid in ETH, BNB, or MATIC when users stream or buy your media.</Text>
        </Box>
        <Box className="uploadcontent_ImageSection flex justify-center items-center my-8">
          <Image src={Subcription2} alt="Subcription2" />
          <Image src={marketPlace} alt="marketPlace" />
          <Image src={Subcription4} alt="Subcription4" />
        </Box>
        <Box className="shadow_Image relative">
          <Image src={shadowbg} alt="shadowbg" />
          <Box className="shadow_btn absolute top-20">
            <Link>
            <Btn value="Become Creator" img={CreatorBtn} padding="14px 18px" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
