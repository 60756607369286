import React from "react";
import "./scss/index.scss";
import { Box, Heading, Link } from "@chakra-ui/react";
import {FaUserCircle} from "react-icons/fa";
import { NavLink } from "react-router-dom";


export const AdminHeader = () => {
  return (
    <Box className="main_admin_Header" width={"100%"} maxWidth="100%">
      <Box
        className="nested_admin_header flex justify-between items-center"
        paddingY={"12px"}
      >
        {/* logo */}
        <Box className="logo_Section flex gap-6 items-center">
          <Heading as={"h1"}>Blokflic Admin Panel</Heading>
        </Box>
        {/* Buttons */}
        <Box className="header_Buttons flex items-center gap-4">
          
            <NavLink to="/admin-user">Users</NavLink>
          <NavLink to="/admin-creator">Creators</NavLink>
          <div className="flex items-center gap-2">
           <p>Lucky Rico</p> 
         {/* <img src={Rico2} alt="Rico2" /> */}
         <FaUserCircle/>
        </div>
        </Box>
      </Box>
    </Box>
  );
};
