import React from "react";
import "./scss/index.scss";
import { TextInput,  Btn } from "component/index";

import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { rightArrow } from "assets/images/index";

export const StepOne = ({ addNumber, setAddNumber, handleChange, input }) => {

  const onSubmitFunc=(e)=>{
     e.preventDefault();    
     setAddNumber(addNumber + 1);
  };

  return (
    <Box className="stepone_FormSection">
      <Flex justify={"flex-start"} className="nested_Stepone py-6 px-5">
        {/* From _Section  */}
        <Box className="">
          <Heading as={"h1"}>Personal Information</Heading>
          <form  onSubmit={onSubmitFunc} className="form_StepOne py-6">
            <Box className="first_section">
           <Box>
            <Box>
              <FormLabel paddingY={"8px"} htmlFor="firstname" paddingX="12px">
                First Name
              </FormLabel>
              <TextInput type="text" id="firstname" name="firstName" value={input.firstName} onChange={(e) => handleChange("firstName" , e)} required={true}   />
            </Box>
            <Box>
              <FormLabel paddingY={"8px"} htmlFor="lastName" paddingX="12px" >
                Last Name
              </FormLabel>
              <TextInput type="text" id="lastName" name="lastName" value={input.lastName} onChange={(e) => handleChange("lastName" , e)} required={true}  />
            </Box>
            <Box>
              <FormLabel paddingY={"8px"} paddingX="12px" htmlFor="email">
                Email
              </FormLabel>
              <TextInput type="email" id="email" name="email"  value={input.email} onChange={(e) => handleChange("email" , e)} required={true}  />
            </Box>
            </Box>
            {/* <Box>
              <FormLabel
                paddingY={"8px"}
                paddingX="12px"
                htmlFor="walletaddress"
              >
                Wallet Address
              </FormLabel>
              <TextInput type="text" id="walletaddress" name="walletAddress" value={input.walletAddress} onChange={(e) => handleChange("walletAddress" , e)} />
            </Box> */}


            <Box className="">
            <Flex gap={"8px"} className="child_Two">
              <Box>
                <FormLabel paddingY={"8px"} paddingX="4px" htmlFor="country">
                  Country
                </FormLabel>
                <TextInput type="text" id="country" name="country" value={input.country} onChange={(e) => handleChange("country" , e)} required={true} />
              </Box>
              <Box>
                <FormLabel paddingY={"8px"} paddingX="4px" htmlFor="state">
                  State
                </FormLabel>
                <TextInput type="text" id="countryState" name="countryState" value={input.countryState} onChange={(e) => handleChange("countryState" , e)} required={true} />
              </Box>
            </Flex>
            <Box>
              <FormLabel paddingY={"8px"} paddingX="4px" id="phone">
                Phone Number
              </FormLabel>
              <TextInput type="number" id="phone"  name="phoneNumber" value={input.phoneNumber} onChange={(e) => handleChange("phoneNumber" , e)}/>
            </Box>
            <Box>
              <FormLabel paddingY={"8px"} paddingX="4px" htmlFor="language">
                Language
              </FormLabel>
              <TextInput type="text" id="language" name="language" value={input.language} onChange={(e) => handleChange("language" , e)} required={true} />
            </Box>


            </Box>

            </Box>
            <Box marginY={"14px"} marginX="4px">
              <Btn
                img={rightArrow}
                value="Next"
                padding="14px"
                bgColor="white"
                // onClick={() => setAddNumber(addNumber + 1)}
              />
            </Box>
          </form>
        </Box>
        {/* Stepper Section */}
        {/* <Flex gap={"28px"} alignItems="center" marginTop={"50px"} className="desc_steoOne">
          <Flex
            direction={"column"}
            className="steppers_lines"
            justify="space-between"
            height={"80vh"}
            marginTop={"-40px"}
            position="relative"
          >
            <Flex
              position={"absolute"}
              left="0px"
              direction={"column"}
              className="stepper_boxess"
              justify="space-between"
              height={"80vh"}
            >
              <Box></Box>
              <Box></Box>
              <Box></Box>
            </Flex>
            <Text>
              Fill out the name that will be visible to you and us, please only
              use alphabets and numeric values and avoid any special characters
              like @, #, $, %, !, ^, &.
            </Text>
            <Text>
              Please Add your Wallet Address & Country, the state field will be unlocked as
              you choose your Country. Please use the correct format for the
              phone number (no need to mention country code while entrying your
              phone number)
            </Text>
            <Text>
              Please choose the language in which you want your application to
              work
            </Text>
          </Flex>
        </Flex> */}
      </Flex>
    </Box>
  );
};
