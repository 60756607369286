export const authConstnts= {
    LOGIN_REQUEST:"USER_LOGIN_REQUEST",
    LOGIN_SUCCESSS : "USER_LOGIN_SUCCESSS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    SIGNUP_REQUEST:"SIGNUP_REQUEST",
    SIGNUP_FAILURE: "SIGNUP_FAILURE",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    OTP_REQUEST: "OTP_REQUEST",
    OTP_SUCCESS: "OTP_SUCCESS",
    OTP_FAILURE: "OTP_FAILURE",
    CREATOR_CREATED: "CREATOR_CREATED",
    CREATOR_WARNING: "CREATOR_WARNING",
    CREATOR_ERROR:"CREATOR_ERROR",
    EMAIL_SENT: "EMAIL_SENT",
    OTP_SENT_Forg_Pass: "OTP_SENT_Forg_Pass", 
    REST_PASS_SUCCUSS: "REST_PASS_SUCCUSS",
    RES_PASS_FAILED: "RES_PASS_FAILED", 
};