import { news_letter_constant } from "../constants";


let initialData={
    isloading:false,
    data: {},

}
export const newsletterReducer=(state=initialData,action)=>{
    console.log("NewsLetter", action);
    switch (action.type) {
        case news_letter_constant.REGISTRATION_SUCESS:
           return{
            ...state,
            data: action.data,
            isloading: false,
           } 
        default:
            return state;
    }

}