import React, { Fragment } from 'react';
import "./scss/index.scss";
import { AdminHeader } from 'layout';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#9D0208',
      color: theme.palette.common.white,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];
  let data=[
    {
        id:1,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:2,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:3,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:4,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:5,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:6,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:7,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:8,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:9,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
    {
        id:10,
        fullName: "Test",
        email: "demo1@gmail.com",
        walletAddress: "21873987213987213987982137",
        country: "America",
        state: "Florida",
        phoneNumber: "213681263876",
        language: "English",
        socialMediaLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
        mediaProtfolioLink: ["https://www.google.com/", "https://www.google.com/", "https://www.google.com/"],
    },
  ]

export const AdminUsers = () => {
  return (
    <div>
        <AdminHeader/>
        <div className='creator_dashboard'>
        <div className='mt-24 text-white mx-auto nested_creator_dashboard'>
        <h1>Users</h1>
        <div className='table_section'>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Full Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Wallet Address</StyledTableCell>
            <StyledTableCell>Country</StyledTableCell>
            <StyledTableCell>State</StyledTableCell>
            <StyledTableCell>Phone Number</StyledTableCell>
            <StyledTableCell>Language</StyledTableCell>
            <StyledTableCell>View Detials</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>
                {row.id}
              </StyledTableCell>                
              <StyledTableCell>
                {row.fullName}
              </StyledTableCell>
              <StyledTableCell>{row.email}</StyledTableCell>
              <StyledTableCell>{row.walletAddress}</StyledTableCell>
              <StyledTableCell>{row.country}</StyledTableCell>
              <StyledTableCell>{row.state}</StyledTableCell>
              <StyledTableCell>{row.phoneNumber}</StyledTableCell>
              <StyledTableCell>{row.language}</StyledTableCell>
              <StyledTableCell>
              <NavLink className='button_link cursor-pointer' to={`/dynamic/${row.id}`}> <span>View Details</span></NavLink>
              </StyledTableCell>
                
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
        </div>
        </div>
    </div>
  )
}
