import React, {useState, useLayoutEffect} from "react";
import "./scss/index.scss";
import { TextInput, Btn } from "component";
import { editIcon } from "assets/images"; 
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';


export const AccountCreator = () => {

  const userData = useSelector((state) => state.authentication.data); // Access the user object from Redux state
  let [accountDetails, setAccountDetails]=useState(userData?.data);
   
 
  useLayoutEffect(() =>{
    if(userData?.data){
      setAccountDetails(userData?.data);
    }else{
      let convertedParse=JSON.parse(localStorage.getItem("auth"));
      // console.log(data);  
      setAccountDetails(convertedParse?.data);
    }
},[]);


// let [oldState, setOldState]=useState({
//   firstName: accountDetails?.firstName,
//   lastName: accountDetails?.lastName,
//   phoneNumber: accountDetails?.phoneNumber, 
//   country: accountDetails?.country,
//   countryState: accountDetails?.countryState, 
// });

  const onChangeFunc=(e) =>{
    setAccountDetails({...accountDetails,[e.target.name]: e.target.value})
  };
   const onSubmitFunc=(e)=>{
    e.preventDefault();
      console.log(accountDetails);
   }

  // console.log("ACCC",user);
  return (
    <div className="account_Section_Creator h-screen w-full px-5">
      {/* <div className="nested_account"> */}
      <div className="mt-20 custom_scroll">
        <h1 className=" px-4">Account Settings</h1>
        <div className="main_card">
          <div className="nested_mainCard">
          <h4 className="mx-7 my-3 py-3 pt-5">Personal Details</h4>
          <div className="edit_section">
            <form onSubmit={onSubmitFunc}>
              <div className="inpout_section_edit m-4 px-5">
              <div>
                <label htmlFor="firstname">First Name</label>
                <TextInput placeholder="First Name" type="text" name="firstName" value={accountDetails?.firstName} onChange={onChangeFunc} />
              </div>
              <div>
                <label htmlFor="firstname">Last Name</label>
                <TextInput placeholder="Last Name" type="text" name="lastName" value={accountDetails?.lastName} onChange={onChangeFunc} />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <div className="content_section">
                {accountDetails?.email && accountDetails?.email}
                </div>
              </div>
              <div>
                <label htmlFor="phonenumber">Country</label>
                <TextInput placeholder="Country" type="text" name="country" value={accountDetails?.country} onChange={onChangeFunc}/>
              </div>

               <div>
                <label htmlFor="phonenumber">State</label>
                <TextInput placeholder="State" type="text" name="countryState" value={accountDetails?.countryState} onChange={onChangeFunc}/>
              </div>


              
              </div>
              <div className="edit_btn mt-3 w-full">
              <Btn value="Edit Info" padding="14px 18px" img={editIcon}  />
              </div>
            </form>
          </div>
          </div>
        </div>
        </div>
      {/* </div> */}
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   user: state.authentication.data,
// });

// export const AccountCreator = connect(mapStateToProps)(AccountCreators);