import { upload_Movies_Constant, get_Movies_Constant } from "../constants";


let initialData={
    isloading:false,
    data: null,

}
export const uploadMoviesReducer=(state=initialData,action)=>{
    console.log("upload_Movies_Constant_action", action);
    switch (action.type) {
        case upload_Movies_Constant.UPLOAD_SUCCESS:
           return{
            ...state,
            data: action.data,
            isloading: false,
           } 
        default:
            return state;
    }

}

export const getMoviesReducer=(state=initialData,action)=>{
    console.log("get_Movies_Constant", action);
    switch (action.type) {
        case get_Movies_Constant.GET_SUCCESS:
           return{
            ...state,
            data: action.data,
            isloading: false,
           } 
        default:
            return state;
    }

}