import { API } from "../config/apis";

// const requestOptions = {
//   method: 'PUT',
//   headers: {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json'
//   },
//   body: JSON.stringify(data)
// };
const signUp = (data) =>
  API.post("/auth/register", data)
    .then((res) => res)
    .catch((err) => err);
// };
const otpCode = (data) =>
  API.post("/auth/verifyOtp", data).then(
    (res) => res,
    (err) => err
  );
const login = (data) =>
  API.post("/auth/login", data)
    .then((res) => res)
    .catch((err) => err);

const forgetPassword = (data) =>
  API.post("/auth/forgotPassword/sendOtp", data).then(
    (res) => res,
    (err) => err
  );

const sent_Otp_ForgPass = (data) =>
  API.post("/auth/forgotPassword/verifyOtp", data).then(
    (res) => res,
    (err) => err
  );
// reset password
const restPassword = (data) =>
  API.post("/auth/forgotPassword/resetPassword", data).then(
    (res) => res,
    (err) => err
  );
// put mehtod for adding creator for
const creatorDetails = (data) =>
  API.put("/user/profile/update", data, {
    headers: {
      token: `${localStorage.getItem("signup-otp")}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res)
    .catch((err) => err);


    const handleDownloadPDF = async () => {
      try {
        const response = await API.get('/api/pdf/download-pdf', {
          responseType: 'blob', // Set responseType to 'blob' to handle binary data
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    };

export const authService = {
  signUp,
  otpCode,
  login,
  forgetPassword,
  sent_Otp_ForgPass,
  restPassword,
  creatorDetails,
  handleDownloadPDF,
};
