import React from "react";
import "./scss/index.scss";
import { Box, Text, Heading, Image, Flex, Link } from "@chakra-ui/react";
import {
  facebookicon,
  twittericon,
  discordicon,
  instagramicon,
  logo_white,
  logo_white_sm,
} from "assets/images/index";

const browse = [
  {
    id: 1,
    linkName: "Disclaimer",
    linkAddress: "#",
  },
  {
    id: 2,
    linkName: "White Paper",
    linkAddress: "#",
  },
  {
    id: 3,
    linkName: "Income Disclosure",
    linkAddress: "#",
  },
  {
    id: 4,
    linkName: "Privacy Policy",
    linkAddress: "#",
  },
];

const help = [
  {
    id: 1,
    linkName: "How to Buy Crypto",
    linkAddress: "#",
  },
  {
    id: 2,
    linkName: "Plans & Pricing",
    linkAddress: "#",
  },
  {
    id: 3,
    linkName: "Supported Devices",
    linkAddress: "#",
  },
  {
    id: 4,
    linkName: "Accessibility",
    linkAddress: "#",
  },
];
const aboutUs = [
  {
    id: 1,
    linkName: "Merchandice",
    linkAddress: "#",
  },
  {
    id: 2,
    linkName: "Affiliate Program",
    linkAddress: "#",
  },
  {
    id: 3,
    linkName: "Ambassador Program",
    linkAddress: "#",
  },
  {
    id: 4,
    linkName: "Contact Us",
    linkAddress: "#",
  },
];
export const Footer = () => {
  return (
    <Box className="footer_Section w-full ">
      <Box className="nested_FooterSection pt-10">
        <Box className="sectionone_Footer py-8 px-9">
          {/* Logo */}
          <Box>
            <Box className="flex py-2">
            <img src={logo_white} alt="logo_white" width={"80px"} height={"80px"} />
            <Heading as={"h1"}>Blokflic</Heading>
            </Box>
            <Text>
              Blokflic and it's partners do not own the media being streamed from this site.  Creators retain 100% ownership rights but have granted Blokflic licensing rights to distribute the material publicly.  Join the conversation and connect with our social media sites.
            </Text>

            <Flex alignItems="center">
              <Link href="#">
                <Image
                  src={facebookicon}
                  alt="facebookicon"
                  width={"54px"}
                  height="54px"
                />
              </Link>
              <Link href="#">
                <Image
                  src={twittericon}
                  alt="twittericon"
                  width={"54px"}
                  height="54px"
                />
              </Link>
              <Link href="#">
                <Image
                  src={discordicon}
                  alt="discordicon"
                  width={"54px"}
                  height="64px"
                />
              </Link>
              <Link href="#">
                <Image
                  src={instagramicon}
                  alt="instagramicon"
                  width={"54px"}
                  height="54px"
                />
              </Link>
            </Flex>
          </Box>
          {/* link one */}
          <Box className="link_section">
            <Heading as="h2" paddingY={"12px"}>
              Browse
            </Heading>
            <Flex direction={"column"}>
              {browse.map((value) => {
                return (
                  <Link href={value.linkAddress} key={value.id}>
                    {value.linkName}
                  </Link>
                );
              })}
            </Flex>
          </Box>
          {/* link two */}
          {/* <Box></Box> */}
          <Box className="link_section">
            <Heading as="h2" paddingY={"12px"}>
              Help
            </Heading>
            <Flex direction={"column"}>
              {help.map((value) => {
                return (
                  <Link href={value.linkAddress} key={value.id}>
                    {value.linkName}
                  </Link>
                );
              })}
            </Flex>
          </Box>
          {/* link three */}
          <Box className="link_section">
            <Heading as="h2" paddingY={"12px"}>
              About Us
            </Heading>
            <Flex direction={"column"}>
              {aboutUs.map((value) => {
                return (
                  <Link href={value.linkAddress} key={value.id}>
                    {value.linkName}
                  </Link>
                );
              })}
            </Flex>
          </Box>
        </Box>
        {/* End section */}
        <Flex justify={"space-between"} align="center" paddingY={"12px"} className="footer_EndSection py-2 px-9 flex-wrap">
          <p>© 2023 Blokflic. All Rights Reserved.</p>
          <div className="flex flex-wrap gap-3">
          <Link href="#">Terms Of Use</Link>
          <Link href="#">Privacy Policy</Link>
          <Link href="#">Privacy Rights</Link>
          <Link href="#">TV Parental Guidelines</Link>
          </div>
        </Flex>
      </Box>
    </Box>
  );
};
