import React,{useState} from "react";
import "./scss/index.scss";
import { Box, Flex, Heading, Text, Checkbox } from "@chakra-ui/react";
import { submit, downloadIcon } from "assets/images/index";
import { Btn } from "component/index";
import CurrentPrice from "./CurrentPrice";
import {authService} from "../../../../../service/auth.service"
import { ModalComp } from "layout/modal";
import { rulsinfroamtioData, subTitle } from "data";

export const StepThree = ({ formSubmit, setCheckedItems, checkedItems , input, handleChangeFunc,setInput}) => {
  
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (e) => {
    setModalOpen(true);
    setCheckedItems(e.target.checked);
  };
  const handleClose = () => setModalOpen(false);
  let [hash,setHash]=useState("");
 
   let {handleDownloadPDF}=authService;

  return (
    <Box className="step_Three">
      <Flex
        justify={"flex-start"}
        gap="24px"
        className="nested_Stepthree py-6 px-5"
      >
        {/* From _Section  */}
        <Box className="child_stepthree">
          <Heading as={"h1"}>Rules & Information</Heading>
          <Box paddingY={"12px"} className="documentation">
            <Box>
              <Heading as={"h2"}>Rules</Heading>
              <Text py={"14px"} letterSpacing="0.4px">
              <div>
                  At Blokflic, we prioritize a safe and enjoyable streaming experience.
                  
                    <ul>
                    <br />
                        <li>
                              <strong>Safety & Respect:</strong> No content that harms health or promotes hate. Racism or threats are not allowed.
                        </li>
                    <br />   
                        <li>
                              <strong>Copyright:</strong> Respect intellectual property rights and copyrights.
                        </li>
                     <br />    
                        <li>
                              <strong>Exclusive Release:</strong> Content on Blokflic must be exclusive to our platform.
                       </li>
                   </ul>
                   <br />
                      <p>Prohibited content includes hate speech, harassment, graphic violence, and explicit material.</p>
                      <p>Read our full <a href="link_here">Content Policy</a>.</p>
                      
                </div>
              </Text>
            </Box>
            <Box>
              <Heading as={"h2"}>Information</Heading>
              <Text py={"14px"} letterSpacing="0.4px">
                Blokflic and it's partners do not own the media being streamed
                from this site. Creators retain 100% ownership rights but have
                granted Blokflic licensing rights to distribute the material
                publicly. Join the conversation and connect with our social
                media sites.
              </Text>
            </Box>

          </Box>
          <Flex justify={"space-between"} align="center" className="cond_btn">
            {/* ChecBox Button & Download Button */}
            <Checkbox
              className="check_Box pl-1"
              //  name="acceptedRules"
              //  value={input.acceptedRules}
              // onChange={(e) => setCheckedItems(e.target.checked)}
              onChange={handleOpen}
            >
              <Text>I understand the applicable rules</Text>
            </Checkbox>
            <Flex align={"center"} gap="8px" cursor={"pointer"}>
              <img src={downloadIcon} alt="downloadIcon" width={"17px"} />{" "}
              {/* <Text> Download rules and information </Text> */}
               <button onClick={handleDownloadPDF}>Download rules and information</button>
            </Flex>
          </Flex>
          <Box>
            <CurrentPrice setInput={setInput} input={input}  setHash={setHash} hash={hash} />
          </Box>
          <Box marginY={"14px"} marginX="4px" className="submti_Btn">
            {hash ? (
                <Btn
                img={submit}
                value="Submit"
                padding="12px"
                bgColor="white"
                onClick={formSubmit}
                disabled={checkedItems === false ? true : false}
              />
            ): "" }
          </Box>
        </Box>
        {/* Stepper Section */}
      </Flex>
      <ModalComp onOpen={modalOpen} onClose={handleClose} setCheckedItems={setCheckedItems} setModalOpen={setModalOpen} dataArray={rulsinfroamtioData} title={"Blokflic Content and Ownership Policy"} subTitle={subTitle} />
    </Box>
  );
};
