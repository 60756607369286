import React,{useEffect, useLayoutEffect, useState} from "react";
import { CreatorProfileHeader, FooterCreatorProf } from "../../../layout/index";
import {NavLink, Outlet} from "react-router-dom";
import { Rico2 , dashboardIcon, Library, account, settings, logout} from "assets/images";
import {FaUser} from "react-icons/fa";
import "./scss/index.scss";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";



export const CreatorProfile = () => {

   const navigate=useNavigate();
   const userData = useSelector((state) => state.authentication.data); // Access the user object from Redux state
   let [creatorAccess, setCreatorAccess]=useState({});
     console.log("userData Sidebar",userData);
   useLayoutEffect(() =>{
     if(userData?.data){
      setCreatorAccess(userData?.data);
     }else{
       let convertedParse=JSON.parse(localStorage.getItem("auth"));
       console.log(convertedParse);  
       setCreatorAccess(convertedParse?.data);
     }
 },[]);


  const logoutFunc=()=>{
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    localStorage.removeItem("signup-otp");
    setTimeout(() =>{
      navigate("/login",{ replace: true });       
    }, 4000);
  }
  useLayoutEffect(() => {
    // Check token and redirect if not present
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to the login page
      window.location.href = '/login';
    }
  }, []);

  return (
    <div className="main_profile">
      <CreatorProfileHeader />
      <div className="flex items-start gap-2">
      <nav className="link_Pages mt-3">
        <div className="nested_links_sidebar pt-9">
          {/* <div> */}
          <div className="w-full flex justify-center items-center user_img">
         {/* <img src={Rico2} alt="Rico2" />  */}
         <FaUser/>
         </div>
         <div className="py-8 flex flex-col justify-between overflow-y-hidden h-full">
          <div>
       <NavLink to={"dashboard-creator"} className="px-4 py-3">
        <img src={dashboardIcon} alt="dashboardIcon" width={"36px"} height="36px" />
        DashBoard
        </NavLink>
         {creatorAccess?.creatorStatus == "pending" || creatorAccess?.creatorStatus == "rejected" ? (
       <div  className="px-4 py-3 flex gap-5 items-center">
       <img src={Library} alt="Library" width={"36px"} height="36px" />
      <span className="text-lg">Library</span> 
      </div>
         ): (
          <NavLink to={"library-creator"} className="px-4 py-3">
          <img src={Library} alt="Library" width={"36px"} height="36px" />
          Library
         </NavLink>
         )}

        <NavLink to={"account-creator"} className="px-4 py-3">
       <img src={account} alt="account" width={"36px"} height="36px" />
        Account</NavLink>
        </div>
        <div>
       <button onClick={logoutFunc} className="px-6 flex gap-3 items-center absolute bottom-24">
       <img src={logout} alt="settings" width={"32px"} height="32px" />
       <span>Log Out</span> 
        </button>
        </div>
       </div>
       {/* </div> */}
       </div>
      </nav>
      <Outlet/>
      </div>
      <FooterCreatorProf/>

    </div>
  );
};
