import React, { useState, useCallback, createContext } from "react";
import "./scss/index.scss";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { RxCross2 } from "react-icons/rx";
import { MediaDetails } from "./media_details";
import { UploadMovie } from "./uploadmovie";
import { Confirmation } from "./confirmation";
import { useDispatch } from "react-redux";
import { uploadMovieAction } from "store/actions/uploadMovie";
import { useSelector } from "react-redux";

let FormData = createContext();

const steps = ["About Media", "upload Media", "Confirmation"];
const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
  width: "75%",
};
export const MediaUpload = ({ openMediaModal, handleCloseMediaModal }) => {
  let [activeState, setActiveState] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedAudio,setSelectedAudio]=useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isStatus, setIsStatus]= useState(false);
  const dispatch = useDispatch();

  const useruploadData = useSelector((state) => state.uploadMoviesRed.data); // Access the user object from Redux state
  console.log("useruploadData",useruploadData);
  const [submitDetails, setSubmitDetails] = useState({
    movieTitle: "",
    description: "",
    selectionGenre: [],
    genre: [],
    director: "",
    writers: "",
    stars: "",
  });

  let getUpdateState = useCallback(() => {
    setActiveState(++activeState);
  }, [activeState]);

  // initial Values
  // const initalValues = {
  //   movieTitle: "",
  //   description: "",
  //   selectionGenre: [],
  //   genre: [],
  //   director: "",
  //   writers: "",
  //   stars: "",
  //   uploadCoverUrl: "",
  //   // uploadMovieUrl: "",
  //   // adultContent: false,
  // };

  // const validationSchema = Yup.object({
  //   movieTitle: Yup.string().required("Required"),
  //   description: Yup.string().required("Required"),
  //   selectionGenre: Yup.array().required("Required"),
  //   director: Yup.string().required("Required"),
  //   writers: Yup.string().required("Required"),
  //   stars: Yup.string().required("Required"),
  //   uploadCoverUrl: Yup.string().required("only png, jpeg, jpg and gif will accept"),
  //   // uploadMovieUrl: Yup.string().required("Required!"),
  // });

  // const onSubmit = (values) => {
  //   console.log("Values", values);
  //   // getUpdateState()

  // };

  let onSubmitFunc = (e) => {
    e.preventDefault();
   let {data}= JSON.parse(localStorage.getItem("auth"))

    let finalobj={
      userId: data?._id,
      ...submitDetails,
    }
    console.log("finalobj", finalobj);
    // setSubmitDetails({...submitDetails, uploadImage: selectedImage})
    dispatch(uploadMovieAction.uploadMoviesFunc(finalobj))
    console.log("Submit Details All Final",submitDetails);
    // const {uploadImageUrl, uploadMovieUrl}=submitDetails;
    // if(useruploadData.status == 201){
      handleCloseMediaModal();
      setSubmitDetails({
        movieTitle: "",
      description: "",
      selectionGenre: [],
      genre: [],
      director: "",
      writers: "",
      stars: "",
      });
      setSelectedVideo(null);
      setSelectedImage(null);
      setSelectedAudio(null);
      setActiveState(0);
      // setTimeout(() =>{
      //   window.location.reload();
      // }, 4000);
    // }

  };

  return (
    <div className="media_upload_section">
      <div className="nested_media_upload mt-20 pl-5">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="media_modal_action"
          open={openMediaModal}
          onClose={handleCloseMediaModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade className="media_upload_modal">
            <Box sx={style}>
              {/* <h1>Hello WOrld</h1> */}
              <div className="nested_box">
                <Box
                  className="cursor-pointer px-8"
                  display={"flex"}
                  justifyContent={"flex-end"}
                  fontSize={"38px"}
                  color={"white"}
                >
                  <RxCross2 onClick={handleCloseMediaModal} />
                </Box>
                <Box sx={{ width: "50%", marginX: "auto" }}>
                  <Stepper activeStep={activeState} alternativeLabel>
                    {steps.map((label, ind) => (
                      <Step key={label} className="stepper_section">
                        <StepLabel
                          onClick={() => setActiveState(ind)}
                          className="stepper_child"
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              {/* Form */}
              <>
                <FormData.Provider
                  value={{
                    getUpdateState,
                    setActiveState,
                    onSubmitFunc,
                    submitDetails,
                    setSubmitDetails,
                    selectedVideo,
                    setSelectedVideo,
                    selectedAudio,
                    setSelectedAudio,
                    selectedImage,
                    setSelectedImage,
                    setIsStatus,
                    isStatus
                  }}
                >
                  {activeState == 0 ? (
                    <MediaDetails />
                  ) : activeState == 1 ? (
                    <UploadMovie />
                  ) : activeState == 2 ? (
                    <Confirmation />
                  ) : null}
                </FormData.Provider>
              </>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};
export { FormData };
