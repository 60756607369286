import React, {useCallback} from 'react';
import "./scss/index.scss";
import { uploadimge, uploadarrow } from 'assets/images';
import { Btn } from 'component';
import {MediaUpload} from "./mediaupload";
export const UploadSection = () => {
  const [openMediaModal, setOpenMediaModal] = React.useState(false);
  const handleOpenMediaModal = useCallback(() => setOpenMediaModal(true),[openMediaModal]);
  const handleCloseMediaModal = useCallback(() => setOpenMediaModal(false),[openMediaModal]);

  return (
    <>
    <div className='upload_section h-screen w-full'>
       <div className='nested_upload_section mt-20  pl-5'>
       <div className="heading">
          <h2>Channel Dashboard</h2>
        </div>
        <div className='mainUploading_section'>
          <div className='nested_uploading_section'>
            <div>
             <h3 className='text-3xl'>upload Media</h3> 
             <p className='text-lg'>3 Steps process for you to easily upload file media (movies) without any hustle</p>
             </div>
             <div className='flex justify-center items-center py-9'>
              <img src={uploadimge} alt="uploadimge" />
              </div>
              <div className='upload_btn'>
              <Btn value='Upload Media' img={uploadarrow} onClick={handleOpenMediaModal} />
              </div>
          </div>      
          <div className='nested_uploading_section series_section'>
          <div>
             <h3 className='text-3xl'>upload Series</h3> 
             <p className='text-lg'>3 Steps process for you to easily upload file media (movies) without any hustle</p>
             </div>
             <div className='flex justify-center items-center py-9'>
              <img src={uploadimge} alt="uploadimge" />
              </div>
              <div className='upload_btn'>
              <Btn value='Upload Media' img={uploadarrow}/>
              </div>
          </div>
        </div>
        </div>  
        <MediaUpload handleCloseMediaModal={handleCloseMediaModal} openMediaModal={openMediaModal} />

    </div>
    </>
  )
}
