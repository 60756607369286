import React, { useState } from "react";
import "./scss/index.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios"

import {
  Box,
  Flex,
  Heading,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { metamask, googleIcons, loginsign, logo_white } from "assets/images/index";
import { TextInput, PasswordInput, Btn } from "component/index";
import Slider from "react-slick";
import { FaUser } from "react-icons/fa";

const slidesData = [
  {
    id: 0,
    text: `Welcome back to Blocflik, the first music and movie streaming platform powered by crypto.`,
  },
  {
    id: 1,
    text: `Viewers can subscribe to the site by loading their accounts with ETH, BNB, or MATIC tokens.  Creators are paid when viewers watch their content.`,
  },
  {
    id: 2,
    text: `Viewers can follow their favorite creators profiles and engage in scheduled live streams and interactive meetups.`,
  },
];

export const Login = () => {
  const dispatch = useDispatch();
  let navigate=useNavigate();
   const selector=useSelector(state => state);
  //  console.log(selector);
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  const [checkedItems, setCheckedItems] = useState(false);
  // let [email, setloginrEmail] = useState("");
  // let [password, setloginPassword] = useState("");
  //   const [check, setChecked] = useState(false);
  //   function isChecked(e) {
  //     console.log(e.target);
  //   }
  // const loginform = {
  //   email,
  //   password,
  //   // checkedItems,
  // };

  // submit form
  // const submitlogin = (e) => {
  //   e.preventDefault();
  //   dispatch(authActions.login(loginform,navigate));
  //   console.log(loginform);
  // };


// formik Section
// initial Values
  let initialValues={
     email: "",
     password: "",
  };
// onSubmit Func
 let onSubmit=(values, onSubmitProps)=>{
  console.log(checkedItems);
  //  console.log("Login Submit", values);  
   dispatch(authActions.login(values,navigate,onSubmitProps))
 };
//  valiation Schema
let validationSchema=Yup.object({
     email: Yup.string().email("Invalid Email Format").required("Email Required"),
     password: Yup.string().required("Password Required"),

});
let formik=useFormik({
   initialValues,
   onSubmit,
   validationSchema,
});


  return (
    <Box className="main_Login w-full">
      <Box className="nested_Login">
        {/* container */}
        <Flex
          className="container_Login"
          height={"100%"}
          width={"90%"}
          marginX={"auto"}
          justify={"left"}
          align="flex-end"
          columnGap={"24px"}
        >
          {/* form section */}
          <Box className="form_section flex justify-center items-center flex-col">
            <Box>
              {/* <img
                src={userSignIcon}
                alt="userSignIcon"
                width={"165px"}
                height="120px"
              /> */}
              <FaUser fontSize={"124px"} />
            </Box>
            <Box paddingY={"12px"}>
              <Heading as={"h2"} className="heading">
                Login
              </Heading>
            </Box>
            <form className="form_inputs" onSubmit={formik.handleSubmit}>

              <Box className="my-7">
              <label htmlFor="email">Email</label>
                <TextInput
                  placeholder={"Email"}
                  type="email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="pl-2">{formik.errors.email}</div>
                ): null}
              </Box>
              <Box className="my-7">
              <label htmlFor="password">Password</label>
                <PasswordInput
                  placeholder={"Password"}
                  name="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="pl-2">{formik.errors.password}</div>
                ): null}
              </Box>
              {/* remember icon and forgot password */}
              <Flex
                justifyContent={"space-between"}
                alignItems="center"
                // paddingX={"18px"}
                className="reminder_link"
              >
                {/* <Radio colorScheme="green" size={"lg"} onClick={isChecked}>
                  Remember me
                </Radio> */}
                <Checkbox
                  className="check_Box pl-1"
                  name="remember_me"
                  onChange={(e) => setCheckedItems(e.target.checked)}
                >
                  Remember Me
                </Checkbox>
                <NavLink to={"/forget-password"} className="underline">
                  Forgot Password
                </NavLink>
              
              </Flex>

              <Box marginY={"20px"} className="login_Btn flex justify-center">
                <Btn value={"Login"} img={loginsign}  disabled={!formik.isValid || formik.isSubmitting}  />
              </Box>
            </form>
            <Text textAlign={"center"} paddingY="20px" fontSize={"16px"}>
              New here ?{" "}
              <NavLink to="/choose-section" className="underline">
                Sign Up
              </NavLink>{" "}
              to choose your role
            </Text>
            {/* <Flex
              justify={"center"}
              align="center"
              flexDirection="column"
              className="btn_groups"
              gap={"18px"}
              marginY="12px"
            >
              <Btn img={metamask} />
              <Btn img={googleIcons} bgColor="white" />
            </Flex> */}
          </Box>

          {/* child one About Company */}
          <Box className="content_section">
            {/* <h1>hell World</h1> */}
            <Box className="logo">
              {/* <Heading as={"h2"}>Logo</Heading> */}
              <img src={logo_white} alt="logo_white" width={"140px"}  />
            </Box>
            <Box className="silder-Sectionlogin">
              <Slider {...settings}>
                {slidesData.map((value) => {
                  return (
                    <Box key={value.id}>
                      <Text>{value.text}</Text>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
