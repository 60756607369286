import React, {useState, useLayoutEffect} from "react";
import "./scss/index.scss";
// import { connect } from 'react-redux';
import { useSelector } from 'react-redux';



export const DashBoardCreator = () => {
  const userData = useSelector((state) => state.authentication.data); // Access the user object from Redux state
  let [creatorEmail, setcreatorEmail]=useState({});

  useLayoutEffect(() =>{
    if(userData?.data){
      setcreatorEmail(userData?.data);
    }else{
      let convertedParse=JSON.parse(localStorage.getItem("auth"));
      // console.log(convertParse);  
      setcreatorEmail(convertedParse?.data);
    }
},[]);

  console.log();
  return (
    <div className="dashboard_creator h-screen w-full flex justify-center items-center flex-col">
      <div className="mt-20">
        <h1>Stay Tune for Something Amazing</h1>
        <h2>Request Submitted</h2>
        <p className="py-4">
          You have completed your registration as a content creator, we will
          futher inform you on your registered email.
        </p>
        <p className="py-8">{creatorEmail?.email && creatorEmail?.email}</p>
        <p className="py-4">
          If you have concern related to your email please <a href="#" className="underline">contact us</a> , and
          submit a request to update the email.
        </p>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   user: state.authentication.data,
// });

// export const DashBoardCreator = connect(mapStateToProps)(DashBoardCreators);