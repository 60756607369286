import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import "./scss/index.scss";
import { useDispatch, useSelector } from "react-redux";
import { uploadMovieAction } from "store/actions";
export const LibraryCreator = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uploadMovieAction.getuploadMoviesFunc());
  }, [dispatch]);
  const userData = useSelector((state) => state.getMoviesRed.data); // Access the user object from Redux state
  const [playingVideoId, setPlayingVideoId] = useState(null);
  let [sectionValue, setSectionValue]=useState("movies")
  const handlePlayClick = (videoId) => {
    setPlayingVideoId(videoId);
  };


   let getSectionValue=(filter) =>{
    setSectionValue(filter);
   }
   
   const getFiterData=() =>{
    if(sectionValue == "movies"){
      return userData?.data.filter(value => value.uploadMovieUrl);  
    }else{
      return userData?.data.filter(value => value.uploadMusicUrl);    
    } 
   };
  let resFilterdata=getFiterData();  
  console.log("userData Reducer", resFilterdata);


  const audioRefs = {};
  const [currentTrack, setCurrentTrack] = useState(null);

  const playTrack = (trackId) => {
    if (currentTrack && currentTrack !== trackId) {
      audioRefs[currentTrack].current.pause();
    }
    setCurrentTrack(trackId);
    audioRefs[trackId].current.play();
  };

  return (


    <div className="library_creator h-screen w-full px-5">
      <div className="mt-20">
        <h1 className="px-4">Library</h1>
          
        <div className="flex items-center flex-wrap mb-16">
      
          {userData == null ? (
            <div className="px-4 py-2">
              <p>Loading...</p>

            </div>
          ) : userData?.length == 0 ? (
            <div className="px-4 py-4">
              <p>There is not item upload yet</p>
            </div>
          ) : (    
           <div className="flex flex-col pl-4 py-5">
           <div className="flex gap-2 btn_section_library">
            <button onClick={() => getSectionValue("movies")}>Movies</button> /
            <button onClick={() => getSectionValue("musics")}>Music</button>  
           </div>
            <div className="flex flex-wrap">

           {sectionValue == "movies" ? 
            userData?.data.map((obj, id) => {
              console.log("obj", obj);
              return (
                <>
                  {obj?.uploadMovieUrl && (
                    <>
                      <div
                        className="main_card_library px-1 py-4"
                        key={obj._id}
                      >
                        {playingVideoId == obj._id ? (
                          <video
                            src={obj?.uploadMovieUrl}
                            width="100%"
                            height="400"
                            controls
                            autoPlay
                          />
                        ) : (
                          <div
                            className="thumbnail-container"
                            onClick={() => handlePlayClick(obj._id)}
                          >
                            <img
                              src={obj?.uploadImageUrl}
                              alt={obj.movieTitle}
                              className="rounded-md"
                              loading="lazy"
                            />
                            <div className="play-button-overlay"></div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              );
            }) : (
              resFilterdata.map(track => (
                <div key={track._id}>
                  <img
                    src={track.uploadImageUrl}
                    alt={track.movieTitle}
                    onClick={() => playTrack(track._id)}
                    style={{ cursor: 'pointer' }}
                  />
                  <audio ref={(ref) => (audioRefs[track._id] = ref)} controls>
                    <source src={track.uploadMusicUrl} type="audio/mpeg" />
                    {/* Your browser does not support the audio element. */}
                  </audio>
                </div>
              ))

            )
            }
</div>
           </div>     
           
          )
          }
        </div>
      </div>
    </div>
  );
};
